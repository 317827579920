import React from 'react'
import InfoTooltipIcon from './icons/InfoTooltipIcon'

const InputExperiment = React.forwardRef(
  (
    {
      placeholder,
      label,
      error,
      type = 'text',
      tooltipMessage,
      options = [],
      ...props
    },
    ref
  ) => {
    let inputField

    if (type === 'select') {
      inputField = (
        <select
          ref={ref}
          {...props}
          className={`py-3 px-4 rounded-lg w-full ${
            error ? 'border-red-500 bg-[#FFE5E5]' : 'bg-[#f2f2f3]'
          } Sans text-[#1e1e1e] font-nunito text-[1rem] font-semibold leading-5`}
        >
          <option value=''>{placeholder}</option>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label || option.value}
            </option>
          ))}
        </select>
      )
    } else {
      inputField = (
        <input
          ref={ref}
          {...props}
          type={type}
          placeholder={placeholder}
          className={`py-3 px-4 rounded-lg w-full ${
            error ? 'border-red-500 bg-[#FFE5E5]' : 'bg-[#f2f2f3]'
          } Sans text-[#1e1e1e] font-nunito text-[1rem] font-semibold leading-5`}
        />
      )
    }

    return (
      <div className='flex flex-col items-start w-full'>
        <label className="pl-2 pb-1 text-center text-neutral-400 text-xs font-semibold font-['Nunito Sans'] leading-tight">
          {label}
        </label>
        <div className='flex justify-center items-center gap-2 self-stretch'>
          {inputField}
          <InfoTooltipIcon tooltipMessage={tooltipMessage} />
        </div>
        {error && (
          <div className='flex items-center gap-2.5 self-stretch py-1 px-3 Sans text-red-500 font-nunito text-[1rem] italic font-bold leading-[normal]'>
            {error.message}
          </div>
        )}
      </div>
    )
  }
)

export default InputExperiment
