import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import Button from '../../components/ui/Button'
import { InputForm } from '../../components/ui/InputForm'
import FormHeader from '../../components/ui/FormHeader'
import { PasswordIcon } from '../../components/ui/icons'
import { resetPasswordRequest } from '../../api/auth.api'
import { changePasswordSchema } from '../../schemas/auth.schema'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'sonner'

const ChangePasswordPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const token = query.get('token')

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    trigger,
  } = useForm({
    resolver: yupResolver(changePasswordSchema),
  })

  const password = watch('newPassword')

  const passwordConditions = {
    length: password?.length >= 8,
    lowercase: /[a-z]/.test(password || ''),
    uppercase: /[A-Z]/.test(password),
    specialChar: /[^a-zA-Z0-9\s]/.test(password),
    noSpaces: !/\s/.test(password),
  }

  const onSubmit = async data => {
    if (data.newPassword !== data.confirmNewPassword) {
      toast('Passwords do not match')
      return
    }
    try {
      // Adjusted to send only the token and the new password to the backend
      const payload = {
        token, // Make sure the token variable is available in this context
        password: data.newPassword, // Rename the key as per backend expectation
      }

      const res = await resetPasswordRequest(payload)

      // Assuming the API returns a success message or similar on successful password change
      if (res.status === 200) {
        // Handle successful password change, e.g., redirect or show a success message
        navigate('/login', { replace: true })
        toast(
          'Password successfully changed. Please log in with your new password.'
        )
      }
    } catch (error) {
      console.log(error)
      toast('Failed to change password. Please try again later.')
    }
  }

  return (
    <div className='flex justify-center items-center h-screen w-screen m-0'>
      <div className='flex w-[27.375rem] p-7 flex-col items-center gap-10 rounded-lg'>
        <FormHeader
          title='Change Password'
          description={'Enter your new password below.'}
        />
        <form
          className='flex flex-col items-start gap-10 self-stretch'
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputForm
            type='password'
            placeholder='Password'
            {...register('newPassword', {
              onChange: e => {
                e.target.value = e.target.value.replace(/\s/g, '')
                trigger('newPassword')
              },
            })}
            aria-invalid={errors.password ? 'true' : 'false'}
            IconComponent={PasswordIcon}
          />
          <div className='text-xs mt-2'>
            {Object.entries(passwordConditions).map(([key, isMet]) => (
              <div
                key={key}
                className={`${
                  isMet ? 'text-green-500' : 'text-black-500'
                } text-xs`}
              >
                {isMet ? '✓' : '•'}
                {key === 'length' &&
                  'Password must be at least 8 characters long'}
                {key === 'lowercase' &&
                  'Password must contain at least one lowercase letter'}
                {key === 'uppercase' &&
                  'Password must contain at least one uppercase letter'}
                {key === 'specialChar' &&
                  'Password must contain at least one special character'}
                {key === 'noSpaces' && 'Password must not contain spaces'}
              </div>
            ))}
          </div>
          <InputForm
            type='password'
            label={'Confirm New Password'}
            placeholder={'Confirm New Password'}
            {...register('confirmNewPassword', {
              validate: value =>
                value === password || 'The passwords do not match',
              onChange: e => {
                e.target.value = e.target.value.replace(/\s/g, '')
                trigger('confirmNewPassword')
              },
            })} // Add closing parenthesis ')' here
            IconComponent={PasswordIcon}
          />
          {errors.confirmNewPassword && (
            <div className='text-red-500'>
              {errors.confirmNewPassword.message}
            </div>
          )}
          <Button color='black' type='submit' disabled={!isValid}>
            Change Password
          </Button>
        </form>
      </div>
    </div>
  )
}

export default ChangePasswordPage
