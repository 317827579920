import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import {
  createExperimentRequest,
  getExperimentRequest,
  getExperimentsRequest,
} from '../../api/experiments.api'
import WelcomeBar from '../../components/ui/WelcomeBar'
import ExperimentsTable from '../../components/ui/ExperimentsTable'
import { DNA } from 'react-loader-spinner'
import ExperimentPlanEdit from '../../components/ui/ExperimentPlanEdit'
import CreateNewIcon from '../../components/ui/icons/CreateNewIcon'
import MastermixTableEdit from '../../components/ui/MastermixTableEdit'
import {
  createMastermixRequest,
  createRecipesRequest,
  updateRecipesRequest,
} from '../../api/mastermix.api'
import { LOCAL_KEY_STORAGE } from '../../config/Configurations'
import { getInstructionData } from '../../components/GenerateInstructionData'
import { toast } from 'sonner'

const ExperimentsPageNew = () => {
  const navigate = useNavigate()
  const { isAuthenticated, isLoading, name } = useAuth()
  const [experiments, setExperiments] = useState([])
  const [experimentDetails, setExperimentDetails] = useState({})
  const [isExperimentsLoading, setExperimentsLoading] = useState(false)
  const [isExperimentsLoaded, setExperimentsLoaded] = useState(false)
  const [isDetailsLoading, setDetailsLoading] = useState(false)

  const { experimentId } = useParams()

  useEffect(() => {
    const getExperimentData = async () => {
      setExperimentsLoading(true)
      try {
        const response = await getExperimentsRequest()
        setExperiments(response.data)
      } catch (error) {
        console.error('Failed to fetch experiments:', error)
      }
      setExperimentsLoading(false)
      setExperimentsLoaded(true)
    }

    const getExperimentDetails = async () => {
      if (experimentId) {
        setDetailsLoading(true)
        try {
          const response = await getExperimentRequest(experimentId)
          setExperimentDetails(response.data)
        } catch (error) {
          console.error('Failed to fetch experiment details:', error)
        }
        setDetailsLoading(false)
      }
    }

    if (!isLoading) {
      if (!isAuthenticated) {
        navigate('/login')
      } else {
        getExperimentData()
        if (experimentId) {
          getExperimentDetails()
        }
      }
    }
  }, [isLoading, isAuthenticated, navigate, experimentId])

  const handleAddMastermix = async experimentId => {
    try {
      const newMastermix = await createMastermixRequest(experimentId, {
        nameOfMasterMix: 'new Mastermix',
      })
      await updateRecipesRequest(newMastermix.data.id, {
        recipes: [
          {
            finalSource: '',
            unit: '',
            finalConcentration: 0,
            tipWashing: '',
            stockConcentration: 0,
            liquidType: '',
            dispenseType: '',
          },
        ],
      })
      setExperimentDetails(prev => ({
        ...prev,
        masterMixes: [...prev.masterMixes, newMastermix.data],
      }))
    } catch (error) {
      console.error('Failed to add mastermix or update recipes:', error)
    }
  }

  if (isLoading || isExperimentsLoading || isDetailsLoading) {
    return (
      <div className='flex items-center justify-center h-screen'>
        <div className='flex flex-col items-center justify-center bg-white p-2.5 rounded-lg grayscale'>
          <DNA height={100} width={100} />
          <div>Loading...</div>
        </div>
      </div>
    )
  }

  const saveLocalStorageData = selectedExperiment => {
    const commonExperimentalPlan = {
      experimentalPlanID: selectedExperiment.id,
      nameOfExperimentalPlan: selectedExperiment.nameOfExperimentalPlan,
      masterMixVolumnPerReaction: selectedExperiment.mastermixVolumePerReaction,
      sampleVolumnPerReaction: selectedExperiment.sampleVolumePerReaction,
      numOfSampleConcentrations: selectedExperiment.numOfSampleConcentrations,
      numOfTechnicalReplicates: selectedExperiment.numOfTechnicalReplicates,
      pcrPlateSize: selectedExperiment.pcrPlateSize,
    }

    localStorage.setItem(
      LOCAL_KEY_STORAGE.COMMON_EXPERIMENTAL_PLAN_FIELDS,
      JSON.stringify([commonExperimentalPlan])
    )

    const allMasterMixesWithRecipes = selectedExperiment.masterMixes.map(
      masterMix => ({
        id: masterMix.id,
        nameOfMasterMix: masterMix.nameOfMasterMix,
        recipeForEachMasterMix: masterMix.recipes.map(recipe => ({
          id: recipe.id,
          dispenseType: recipe.dispenseType,
          finalConcentration: recipe.finalConcentration,
          finalSource: recipe.finalSource,
          liquidType: recipe.liquidType,
          stockConcentration: recipe.stockConcentration,
          tipWashing: recipe.tipWashing,
          unit: recipe.unit,
        })),
      })
    )

    localStorage.setItem(
      LOCAL_KEY_STORAGE.RECIPE_STEPS,
      JSON.stringify(allMasterMixesWithRecipes)
    )

    const instructionData = getInstructionData(allMasterMixesWithRecipes, [
      commonExperimentalPlan,
    ])
    localStorage.setItem(
      LOCAL_KEY_STORAGE.EXPERIMENTAL_PLAN,
      JSON.stringify(instructionData)
    )
  }

  const handleView = experimentId => {
    const selectedExperiment = experiments.find(
      experiment => experiment.id === experimentId
    )
    saveLocalStorageData(selectedExperiment)
    navigate(`/experiments/${experimentId}/instructions`)
  }

  const handleCopy = async experimentId => {
    const selectedExperiment = experiments.find(
      experiment => experiment.id === experimentId
    )
    const newExperiment = {
      nameOfExperimentalPlan: selectedExperiment.nameOfExperimentalPlan,
      mastermixVolumePerReaction: selectedExperiment.mastermixVolumePerReaction,
      sampleVolumePerReaction: selectedExperiment.sampleVolumePerReaction,
      pcrPlateSize: selectedExperiment.pcrPlateSize,
      numOfSampleConcentrations: selectedExperiment.numOfSampleConcentrations,
      numOfTechnicalReplicates: selectedExperiment.numOfTechnicalReplicates,
    }

    const experimentCreated = await createExperimentRequest(newExperiment)

    selectedExperiment.masterMixes.forEach(async mastermix => {
      const newMastermix = {
        nameOfMasterMix: mastermix.nameOfMasterMix,
      }
      const mastermixCreated = await createMastermixRequest(
        experimentCreated.data.id,
        newMastermix
      )

      const newRecipes = mastermix.recipes.map(recipe => ({
        dispenseType: recipe.dispenseType,
        finalConcentration: recipe.finalConcentration,
        finalSource: recipe.finalSource,
        liquidType: recipe.liquidType,
        stockConcentration: recipe.stockConcentration,
        tipWashing: recipe.tipWashing,
        unit: recipe.unit,
      }))
      await createRecipesRequest(mastermixCreated.data.id, newRecipes)
    })
    toast.success('Experimental plan cloned successfully')
    setTimeout(() => {
      window.location.reload()
    }, 2000)
  }

  const handleUploadFile = experimentId => {
    const experiment = experiments.find(
      experiment => experiment.id === experimentId
    )

    navigate(`/experiments/${experimentId}/documents`, {
      state: { experimentName: experiment.nameOfExperimentalPlan },
    })
  }

  const handleGenerateWorklistFiles = async experimentId => {

    const experiment = await getExperimentRequest(experimentId)

    navigate(`/experiments/${experimentId}/worklist-files`, {
      state: { experimentDetails: experiment.data },
    })
  }

  const handleEdit = experimentId => {
    const selectedExperiment = experiments.find(
      experiment => experiment.id === experimentId
    )
    navigate(`/experiments/${experimentId}`, {
      state: { experimentDetails: selectedExperiment },
    })
  }

  const actions = {
    handleEdit,
    handleView,
    handleCopy,
    handleUploadFile,
    handleGenerateWorklistFiles,
  }

  return (
    <>
      {experimentId ? (
        <div className='flex flex-col mx-20 mt-7'>
          <div className='flex justify-between'>
            <div className='flex flex-col py-3 gap-5 text-[#434447] font-nunito text-4xl font-bold leading-6'>
              Edit Experimental Plan:
              <span>{experimentDetails.nameOfExperimentalPlan}</span>
            </div>
          </div>
          <ExperimentPlanEdit
            experimentalPlanData={experimentDetails}
            experimentId={experimentId}
            setExperimentDetails={setExperimentDetails}
            actions={actions}
          />
          <div className='flex justify-between'>
            <div className='Sans pb-3 mt-7 text-[#434447] font-nunito text-4xl font-bold leading-6'>
              Mastermix
            </div>
            <button
              onClick={() => handleAddMastermix(experimentId)}
              className='flex items-center gap-2 py-2 pl-3 pr-4 rounded-lg'
            >
              <CreateNewIcon />
              <div className='Sans text-[#58595c] text-center font-nunito font-bold leading-6'>
                Add mastermix
              </div>
            </button>
          </div>
          <MastermixTableEdit
            experimentId={experimentId}
            newMastermixesTrigger={experimentDetails.masterMixes}
          />
        </div>
      ) : (
        <div className='mt-10 mx-20'>
          <WelcomeBar
            name={name}
            areExperiments={experiments.length > 0 || isExperimentsLoaded}
          />
          {experiments.length === 0 && isExperimentsLoaded ? (
            <div className='flex items-center justify-center'>
              <div className='flex flex-col mt-5 w-1/2 items-center justify-center bg-white p-6 rounded-lg shadow-lg'>
                <div className='text-center'>
                  <span className='text-xl'>
                    🔬 <strong>No experiments found.</strong>
                  </span>
                  <p className='mt-2 text-sm text-gray-600'>
                    Looks like your lab is too clean!
                  </p>
                  <ul className='list-disc list-inside mt-4 text-left'>
                    <li>
                      <strong>
                        To start creating a new experimental plan:
                      </strong>{' '}
                      Click the <em>New experimental plan</em> button at the top
                      right and choose the <em>Create new experimental plan</em>{' '}
                      option. This will guide you through a step-by-step process
                      to design your experiment, including selecting mastermixes
                      and reagents.
                    </li>
                    <li className='mt-4'>
                      <strong>To import an existing experimental plan:</strong>{' '}
                      Select the <em>Import Existing Experimental Plan</em>{' '}
                      option from the same menu to upload a file that was
                      previously saved.
                    </li>
                  </ul>
                  <p className='text-sm mt-5 text-gray-600'>
                    Ready to unleash some science? Let's shake things up in the
                    lab! 🧪
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <ExperimentsTable experiments={experiments} actions={actions} />
          )}
        </div>
      )}
    </>
  )
}

export default ExperimentsPageNew
