export const UserIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='12' cy='7' r='4' stroke='#121314' strokeWidth='2' />
    <path
      d='M5 18.8C5 16.7013 6.70132 15 8.8 15H15.2C17.2987 15 19 16.7013 19 18.8C19 20.015 18.015 21 16.8 21H7.2C5.98497 21 5 20.015 5 18.8Z'
      stroke='#121314'
      strokeWidth='2'
    />
  </svg>
)
