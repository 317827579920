import React from 'react'

function RADALogoInverted({ scale = 1 }) {
  const originalWidth = 719
  const originalHeight = 308

  const scaledWidth = originalWidth * scale
  const scaledHeight = originalHeight * scale

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.0'
      width={`${scaledWidth}pt`}
      height={`${scaledHeight}pt`}
      viewBox='0 0 719.000000 308.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,308.000000) scale(0.100000,-0.100000)'
        fill='#000000'
        stroke='none'
      >
        <path d='M681 2953 c-145 -4 -171 -10 -171 -42 1 -39 15 -45 113 -43 l94 3 7 -62 c3 -34 6 -113 6 -175 0 -63 2 -114 4 -114 2 0 17 7 32 15 40 21 65 19 134 -10 69 -29 134 -32 206 -10 27 8 69 15 93 15 l44 0 -4 173 -4 172 80 3 c44 1 90 4 101 7 27 6 37 30 24 56 -10 18 -24 19 -313 17 -166 -1 -367 -4 -446 -5z' />
        <path d='M769 2487 l-34 -23 4 -309 c2 -171 7 -438 10 -595 6 -266 8 -288 29 -327 81 -155 321 -179 436 -43 49 59 50 64 37 696 -6 323 -14 591 -17 596 -8 13 -66 10 -120 -7 -73 -22 -144 -19 -220 10 -81 31 -83 31 -125 2z m380 -192 c30 -15 42 -42 34 -75 -6 -21 -50 -50 -76 -50 -8 0 -26 9 -41 21 -35 28 -35 70 0 98 32 25 46 26 83 6z m-204 -195 c24 -27 22 -70 -5 -92 -65 -53 -160 21 -109 85 26 34 86 38 114 7z m214 -256 c27 -34 26 -50 -4 -79 -47 -48 -125 -20 -125 44 0 60 90 84 129 35z m-215 -205 c62 -49 4 -135 -73 -108 -57 20 -63 97 -8 119 35 14 52 12 81 -11z m196 -229 c62 -62 -43 -150 -108 -90 -46 43 -13 110 53 110 22 0 43 -8 55 -20z' />
        <path d='M2460 1756 l0 -656 190 0 190 0 2 313 3 312 61 -1 c78 -1 121 -25 154 -84 22 -42 25 -58 31 -240 5 -136 12 -211 23 -248 l17 -52 204 0 c113 0 205 2 205 4 0 2 -9 24 -20 48 -18 42 -34 128 -59 328 -15 113 -46 175 -108 217 -26 18 -63 38 -81 44 l-34 12 79 37 c84 39 143 94 171 159 24 56 22 185 -5 242 -40 88 -124 153 -253 195 -48 15 -103 18 -412 22 l-358 4 0 -656z m520 523 c97 -21 149 -94 150 -209 0 -87 -16 -138 -54 -176 -38 -38 -89 -54 -173 -54 l-63 0 0 218 c0 120 3 222 7 225 10 10 76 8 133 -4z' />
        <path d='M3960 2406 c0 -2 -85 -293 -190 -646 -104 -353 -190 -646 -190 -651 0 -5 33 -9 73 -9 l74 0 40 137 40 138 198 0 198 0 37 -135 36 -135 192 -3 c106 -1 192 0 192 3 0 3 -76 278 -169 612 -93 335 -174 627 -181 651 l-11 42 -169 0 c-94 0 -170 -2 -170 -4z m129 -607 c39 -146 71 -269 71 -273 0 -3 -70 -6 -156 -6 -143 0 -155 1 -150 18 3 9 37 127 76 262 77 270 79 275 84 269 2 -2 36 -124 75 -270z' />
        <path d='M4760 1754 l0 -656 343 4 c376 5 389 7 511 71 113 60 188 159 236 312 34 105 40 353 12 486 -50 244 -182 381 -406 424 -58 11 -155 15 -387 15 l-309 0 0 -656z m593 513 c55 -32 90 -97 109 -202 21 -111 17 -539 -5 -640 -34 -156 -90 -207 -234 -214 l-83 -3 0 539 c0 297 3 543 8 547 4 5 41 6 83 4 60 -4 86 -10 122 -31z' />
        <path d='M6305 2398 c-2 -7 -88 -296 -190 -643 -102 -346 -188 -636 -190 -643 -4 -10 13 -12 73 -10 l77 3 40 137 40 138 196 0 197 0 30 -112 c17 -62 34 -125 38 -140 l6 -28 195 0 c162 0 194 2 190 14 -3 7 -83 292 -177 632 -95 341 -176 629 -181 642 -8 22 -12 22 -174 22 -124 0 -167 -3 -170 -12z m129 -591 c38 -144 71 -268 73 -274 4 -10 -30 -13 -151 -13 -86 0 -156 3 -156 8 0 17 154 542 159 542 3 0 37 -118 75 -263z' />
        <path d='M444 2022 c-22 -14 -69 -86 -171 -263 -152 -263 -173 -302 -173 -319 0 -6 55 -10 160 -10 115 0 160 3 160 12 0 6 54 105 120 219 83 144 120 218 120 239 0 38 -19 77 -54 109 -36 34 -118 40 -162 13z' />
        <path d='M1402 2020 c-41 -25 -77 -100 -67 -138 3 -14 57 -114 119 -221 62 -108 115 -204 119 -213 6 -16 23 -18 166 -18 l161 0 -11 28 c-19 51 -300 530 -324 552 -36 33 -118 38 -163 10z' />
        <path d='M100 1337 c0 -13 99 -190 301 -538 115 -199 101 -196 194 -34 l78 136 -20 27 c-32 45 -233 398 -233 410 0 9 -45 12 -160 12 -127 0 -160 -3 -160 -13z' />
        <path d='M1573 1333 c-10 -26 -223 -395 -239 -414 -8 -8 -14 -19 -14 -23 0 -8 138 -247 151 -261 6 -6 14 -4 24 5 25 26 395 674 395 692 0 16 -13 18 -155 18 -139 0 -156 -2 -162 -17z' />
        <path d='M707 803 c-88 -148 -118 -204 -112 -213 8 -13 792 -14 799 -1 4 5 -28 68 -70 140 l-76 131 -253 0 -254 0 -34 -57z' />
        <path d='M2475 822 c-30 -6 -55 -32 -55 -57 0 -25 20 -45 68 -69 58 -30 33 -84 -34 -71 -28 5 -35 3 -32 -7 3 -7 23 -14 47 -16 99 -7 113 91 18 127 -54 21 -41 75 16 69 17 -2 32 2 34 8 4 13 -31 22 -62 16z' />
        <path d='M4260 820 c-14 -3 -31 -18 -38 -33 -17 -35 -3 -62 46 -85 66 -31 50 -89 -21 -78 -29 5 -38 3 -35 -6 7 -21 87 -22 110 -2 30 28 20 74 -22 101 -59 38 -61 42 -49 65 9 16 17 19 45 15 24 -4 34 -2 34 7 0 16 -36 24 -70 16z' />
        <path d='M4412 815 c-55 -24 -47 -83 15 -112 28 -13 39 -25 41 -45 4 -31 -21 -43 -69 -33 -24 6 -30 4 -27 -6 3 -8 23 -15 47 -17 102 -8 110 102 10 133 -15 5 -19 14 -17 33 3 24 7 27 41 26 23 -1 37 3 37 11 0 18 -44 24 -78 10z' />
        <path d='M5013 815 c-67 -28 -84 -139 -30 -193 38 -38 101 -35 139 7 23 25 28 41 28 81 0 84 -67 135 -137 105z m86 -41 c42 -53 13 -154 -44 -154 -12 0 -33 9 -46 19 -20 16 -24 29 -24 71 0 42 4 55 24 71 33 27 66 24 90 -7z' />
        <path d='M6594 816 c-43 -19 -64 -53 -64 -103 0 -70 19 -96 83 -117 29 -9 84 20 101 53 33 63 10 142 -49 167 -40 16 -32 16 -71 0z m66 -26 c10 -5 24 -26 30 -45 30 -91 -55 -167 -109 -99 -56 71 5 183 79 144z' />
        <path d='M2590 710 c0 -91 3 -110 15 -110 12 0 15 19 15 110 0 91 -3 110 -15 110 -12 0 -15 -19 -15 -110z' />
        <path d='M2676 798 c-3 -13 -7 -62 -10 -110 -5 -72 -3 -88 8 -88 10 0 16 22 21 82 l7 83 28 -78 c15 -43 30 -81 34 -84 11 -12 24 10 55 92 l30 80 1 -50 c1 -74 11 -125 25 -125 10 0 12 21 8 88 -6 114 -10 132 -31 132 -13 0 -25 -23 -48 -87 l-31 -88 -29 88 c-21 66 -32 87 -46 87 -10 0 -20 -9 -22 -22z' />
        <path d='M2930 710 c0 -91 3 -110 15 -110 11 0 15 11 15 43 0 40 1 42 33 44 43 3 81 46 72 82 -9 37 -32 51 -87 51 l-48 0 0 -110z m94 74 c20 -19 20 -24 0 -52 -8 -12 -26 -22 -40 -22 -22 0 -24 4 -24 45 0 41 2 45 24 45 14 0 32 -7 40 -16z' />
        <path d='M3100 710 l0 -110 65 0 c75 0 89 17 18 22 l-48 3 -3 98 c-2 77 -6 97 -17 97 -12 0 -15 -20 -15 -110z' />
        <path d='M3260 710 c0 -91 3 -110 15 -110 12 0 15 19 15 110 0 91 -3 110 -15 110 -12 0 -15 -19 -15 -110z' />
        <path d='M3340 710 c0 -91 3 -110 15 -110 11 0 15 12 15 50 l0 50 40 0 c22 0 40 5 40 10 0 6 -18 10 -40 10 l-40 0 0 40 0 40 45 0 c25 0 45 5 45 10 0 6 -27 10 -60 10 l-60 0 0 -110z' />
        <path d='M3495 808 c-3 -8 -4 -57 -3 -109 2 -76 6 -94 18 -94 12 0 15 18 15 104 0 75 -4 106 -12 108 -7 3 -15 -2 -18 -9z' />
        <path d='M3570 710 l0 -110 65 0 c75 0 89 17 18 22 -48 3 -48 3 -51 41 l-3 37 46 0 c60 0 57 24 -4 28 -41 3 -42 4 -39 35 3 30 6 32 46 35 64 5 50 22 -18 22 l-60 0 0 -110z' />
        <path d='M3737 813 c-4 -3 -7 -53 -7 -110 l0 -103 61 0 c54 0 64 3 90 29 44 44 43 122 -2 164 -24 22 -38 27 -82 27 -30 0 -57 -3 -60 -7z m117 -34 c21 -16 26 -29 26 -64 0 -58 -24 -87 -76 -89 l-39 -1 -3 74 c-2 41 -1 80 2 88 8 20 60 16 90 -8z' />
        <path d='M4075 808 c-18 -47 -65 -193 -65 -200 0 -23 28 -4 36 25 8 28 14 32 47 35 35 3 39 1 52 -32 9 -20 22 -36 29 -36 11 0 6 26 -22 110 -29 86 -40 110 -55 110 -9 0 -20 -6 -22 -12z m45 -100 c0 -13 -8 -18 -25 -18 -29 0 -29 0 -13 53 l12 40 13 -29 c6 -16 12 -37 13 -46z' />
        <path d='M4595 808 c-18 -47 -65 -193 -65 -200 0 -23 28 -4 36 25 8 28 14 32 47 35 35 3 39 1 52 -32 9 -20 22 -36 31 -36 9 0 14 6 12 13 -3 6 -20 56 -38 110 -26 76 -37 97 -52 97 -10 0 -21 -6 -23 -12z m38 -73 l16 -45 -30 0 c-16 0 -29 3 -29 6 0 10 23 84 26 84 1 0 9 -20 17 -45z' />
        <path d='M4734 753 c25 -47 36 -80 36 -110 0 -32 4 -43 15 -43 11 0 15 12 15 50 0 36 7 61 23 88 47 73 50 82 29 82 -12 0 -27 -18 -44 -52 l-25 -53 -27 53 c-15 29 -33 52 -42 52 -12 0 -7 -15 20 -67z' />
        <path d='M5190 710 c0 -91 3 -110 15 -110 11 0 15 11 15 43 0 40 1 42 32 44 60 4 92 63 57 106 -17 23 -28 27 -70 27 l-49 0 0 -110z m88 78 c29 -29 7 -78 -35 -78 -21 0 -23 5 -23 45 0 40 2 45 23 45 13 0 28 -5 35 -12z' />
        <path d='M5340 805 c0 -10 10 -15 30 -15 l30 0 0 -95 c0 -78 3 -95 15 -95 12 0 15 17 15 94 l0 95 32 3 c17 2 33 9 36 16 3 9 -17 12 -77 12 -65 0 -81 -3 -81 -15z' />
        <path d='M5520 710 c0 -91 3 -110 15 -110 12 0 15 19 15 110 0 91 -3 110 -15 110 -12 0 -15 -19 -15 -110z' />
        <path d='M5607 744 c-11 -119 -9 -144 8 -144 12 0 15 15 15 75 0 41 4 75 8 75 4 0 18 -31 31 -70 34 -106 45 -105 83 13 12 37 26 67 30 67 4 0 8 -36 8 -80 0 -64 3 -80 15 -80 17 0 20 52 9 159 -6 49 -10 61 -25 61 -13 0 -25 -20 -46 -80 -15 -44 -30 -80 -33 -80 -3 0 -17 35 -31 78 -20 60 -30 78 -46 80 -18 3 -21 -4 -26 -74z' />
        <path d='M5865 808 c-3 -8 -4 -57 -3 -109 2 -76 6 -94 18 -94 12 0 15 18 15 104 0 75 -4 106 -12 108 -7 3 -15 -2 -18 -9z' />
        <path d='M5940 810 c0 -5 23 -10 50 -10 30 0 50 -4 50 -11 0 -6 -25 -47 -55 -91 -30 -44 -55 -84 -55 -89 0 -5 36 -9 80 -9 47 0 80 4 80 10 0 6 -25 10 -55 10 -32 0 -55 4 -55 10 0 6 23 44 50 85 74 110 75 105 -14 105 -44 0 -76 -4 -76 -10z' />
        <path d='M6149 723 c-17 -54 -34 -104 -37 -110 -2 -7 3 -13 11 -13 9 0 20 15 27 35 10 32 15 35 49 35 33 0 38 -4 50 -35 7 -19 19 -35 28 -35 8 0 13 6 11 13 -3 6 -20 56 -37 110 -26 76 -37 97 -51 97 -14 0 -25 -21 -51 -97z m60 35 c25 -67 25 -68 -9 -68 -29 0 -32 2 -25 23 3 12 9 32 12 45 3 12 7 22 9 22 3 0 8 -10 13 -22z' />
        <path d='M6272 808 c3 -7 19 -14 36 -16 l32 -3 0 -95 c0 -77 3 -94 15 -94 12 0 15 17 15 94 l0 95 32 3 c17 2 33 9 36 16 3 9 -18 12 -83 12 -65 0 -86 -3 -83 -12z' />
        <path d='M6460 710 c0 -91 3 -110 15 -110 12 0 15 19 15 110 0 91 -3 110 -15 110 -12 0 -15 -19 -15 -110z' />
        <path d='M6764 807 c-10 -27 1 -200 13 -204 9 -3 13 20 15 84 l3 87 50 -84 c28 -47 59 -86 68 -88 15 -3 17 8 17 107 0 92 -3 111 -15 111 -12 0 -15 -16 -15 -82 l0 -82 -51 82 c-47 78 -73 99 -85 69z' />
        <path d='M700 435 c0 -78 2 -88 25 -110 l24 -25 240 0 c132 0 247 3 256 6 29 12 45 60 45 139 l0 75 -295 0 -295 0 0 -85z' />
        <path d='M790 175 l0 -65 205 0 205 0 0 65 0 65 -205 0 -205 0 0 -65z' />
      </g>
    </svg>
  )
}

export default RADALogoInverted
