import { useEffect, useState } from 'react'
import { getExperimentRequest } from '../../api/experiments.api'
import { Link, useParams } from 'react-router-dom'
import ViewExperimentalPlanTable from '../../components/ViewExperimentalPlanTable'
import CreateNewExperiment from '../../components/CreateNewExperiment'
import RecipesTable from '../../components/recipes/RecipesTable'
import AddNewStep from '../../components/AddNewStep'
import EditMastermixButton from '../../components/EditMastermix'
import { LOCAL_KEY_STORAGE } from '../../config/Configurations'
import { Button } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'

function ExperimentPage() {
  const params = useParams()
  const [experimentalPlanData, setExperimentalPlanData] = useState([])

  const [openExperimentalPlanForm, setOpenExperimentalPlanForm] =
    useState(false)
  const [isEditExperimentalPlanForm] =
    useState(true)

  const [openInputForm, setOpenInputForm] = useState(false)
  const [isEditForm, setIsEditForm] = useState(false)
  const [nameOfMastermix, setNameOfMastermix] = useState('')
  const [recipes, setRecipes] = useState([])

  const [rowId, setRowId] = useState(0)

  const [activeStep, setActiveStep] = useState(0)

  const handleEditExperimentalPlan = async rowId => {
    setOpenExperimentalPlanForm(true)
    // this.setState({
    //   openExperimentalPlanForm: true,
    //   isEditExperimentalPlanForm: true,
    //   rowId: rowId,
    // })

    // const data = [...experimentalPlanData] // copy the current list
    // var index = data.findIndex(row => row.experimentalPlanID === rowId) // find matching row ID to update

    // send the data to the api
    // const updatedExperiment = {
    //   nameOfExperimentalPlan: data[index].nameOfExperimentalPlan,
    //   masterMixVolumnPerReaction: data[index].masterMixVolumnPerReaction,
    //   sampleVolumnPerReaction: data[index].sampleVolumnPerReaction,
    //   numOfSampleConcentrations: data[index].numOfSampleConcentrations,
    //   numOfTechnicalReplicates: data[index].numOfTechnicalReplicates,
    //   pcrPlateSize: data[index].pcrPlateSize,
    // }

    // // send request to update to the api
    // const res = await updateExperimentRequest(
    //   data[index].experimentalPlanID,
    //   updatedExperiment
    // )

    // this.setState({
    //   nameOfExperimentalPlan: data[index].nameOfExperimentalPlan,
    //   masterMixVolumnPerReaction: data[index].masterMixVolumnPerReaction,
    //   sampleVolumnPerReaction: data[index].sampleVolumnPerReaction,
    //   numOfSampleConcentrations: data[index].numOfSampleConcentrations,
    //   numOfTechnicalReplicates: data[index].numOfTechnicalReplicates,
    //   pcrPlateSize: data[index].pcrPlateSize,
    // })
  }
  const fetchExperiment = async () => {
    const res = await getExperimentRequest(params.experimentId)
    setExperimentalPlanData([res.data])
  }

  useEffect(() => {
    fetchExperiment()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCloseExperimentalForm = (event, reason) => {
    // Pressing 'Esc' or clicking on the backdrop won't close the form
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      // this.setState({ openExperimentalPlanForm: false })
      setOpenExperimentalPlanForm(false)
    }
  }

  const handleCreateMasterMix = () => {
    setOpenInputForm(true)
    setIsEditForm(false)
  }

  const handleEditMasterMix = rowId => {
    setOpenInputForm(true)
    setIsEditForm(true)
    setRowId(rowId)

    const data = [...experimentalPlanData[0].masterMixes]
    var index = data.findIndex(row => row.id === rowId)

    setNameOfMastermix(data[index].nameOfMasterMix)
    setRecipes(data[index].recipes)
  }

  const goToStep = step => setActiveStep(step)

  const handleBack = () => {
    setActiveStep(prevState => prevState - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const handleNext = () => {
    setActiveStep(prevState => prevState + 1)
  }

  const handleCloseForm = (event, reason) => {
    // Pressing 'Esc' or clicking on the backdrop won't close the form
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      // this.setState({ openInputForm: false })
      setOpenInputForm(false)

      // Clear the form
      handleReset()

      // fetch experiment
      fetchExperiment()
    }
  }

  const handleFinishForm = () => {
    // this.setState({
    //   activeStep: 0,
    //   openInputForm: false,
    //   isAddedNewMastermixSucceed: true,
    //   listOfRecipeSteps: JSON.parse(
    //     localStorage.getItem(LOCAL_KEY_STORAGE.RECIPE_STEPS)
    //   ),
    // })
    // this.clearState()

    setActiveStep(0)
    setOpenInputForm(false)
    // setIsAddedNewMastermixSucceed(true)
  }

  const saveEditedForm = async newData => {
    setActiveStep(0)
    setOpenInputForm(false)
    // setIsAddedNewMastermixSucceed(true)

    // const res = await axios.put(
    //   `${process.env.REACT_APP_BACKEND_URL}/mastermixes/${rowId}`,
    //   newData
    // )

    // send request to backend to update the mastermix recipes

    // setNameOfMastermix('')
    // setRecipes([])

    // this.setState({
    //   activeStep: 0,
    //   openInputForm: false,
    //   isAddedNewMastermixSucceed: true,
    //   listOfRecipeSteps: JSON.parse(
    //     localStorage.getItem(LOCAL_KEY_STORAGE.RECIPE_STEPS)
    //   ),

    //   // Clear state
    //   nameOfMastermix: '',
    //   recipeForEachMasterMix: [
    //     {
    //       finalSource: '',
    //       unit: '',
    //       finalConcentration: -1,
    //       tipWashing: '',
    //       stockConcentration: -1,
    //       liquidType: '',
    //       dispenseType: '',
    //     },
    //   ],
    //   masterMixVolumnPerReaction: -1,
    //   sampleVolumnPerReaction: -1,
    //   numOfSampleConcentrations: -1,
    //   numOfTechnicalReplicates: -1,
    //   pcrPlateSize: '',
    //   rowId: 0,
    // })
  }

  // if (experimentalPlanData.length > 0) {
  //   const commonExperimentalPlan = {
  //     experimentalPlanID: experimentalPlanData[0].id,
  //     nameOfExperimentalPlan: experimentalPlanData[0].nameOfExperimentalPlan,
  //     masterMixVolumnPerReaction:
  //       experimentalPlanData[0].masterMixVolumnPerReaction,
  //     sampleVolumnPerReaction: experimentalPlanData[0].sampleVolumnPerReaction,
  //     numOfSampleConcentrations:
  //       experimentalPlanData[0].numOfSampleConcentrations,
  //     numOfTechnicalReplicates:
  //       experimentalPlanData[0].numOfTechnicalReplicates,
  //     pcrPlateSize: experimentalPlanData[0].pcrPlateSize,
  //   }

  //   localStorage.setItem(
  //     LOCAL_KEY_STORAGE.COMMON_EXPERIMENTAL_PLAN_FIELDS,
  //     JSON.stringify(commonExperimentalPlan)
  //   )
  //   // add all recipes
  //   const allRecipes = []
  //   experimentalPlanData[0].masterMixes.forEach(masterMix => {
  //     masterMix.recipes.forEach(recipe => {
  //       allRecipes.push(recipe)
  //     })
  //   })

  //   localStorage.setItem(
  //     LOCAL_KEY_STORAGE.RECIPE_STEPS,
  //     JSON.stringify(allRecipes)
  //   )
  // }

  if (experimentalPlanData.length > 0) {
    const commonExperimentalPlan = {
      experimentalPlanID: experimentalPlanData[0].id,
      nameOfExperimentalPlan: experimentalPlanData[0].nameOfExperimentalPlan,
      // fixed typo from Volumn to Volume
      masterMixVolumnPerReaction:
        experimentalPlanData[0].mastermixVolumePerReaction, // fixed typo from masterMixVolumn to mastermixVolume
      // fixed typo from Volumn to Volume
      sampleVolumnPerReaction: experimentalPlanData[0].sampleVolumePerReaction, // fixed typo from sampleVolumn to sampleVolume
      numOfSampleConcentrations:
        experimentalPlanData[0].numOfSampleConcentrations,
      numOfTechnicalReplicates:
        experimentalPlanData[0].numOfTechnicalReplicates,
      pcrPlateSize: experimentalPlanData[0].pcrPlateSize,
    }

    localStorage.setItem(
      LOCAL_KEY_STORAGE.COMMON_EXPERIMENTAL_PLAN_FIELDS,
      JSON.stringify([commonExperimentalPlan])
    )

    const allMasterMixesWithRecipes = experimentalPlanData[0].masterMixes.map(
      masterMix => ({
        id: masterMix.id,
        nameOfMasterMix: masterMix.nameOfMasterMix,
        recipeForEachMasterMix: masterMix.recipes.map(recipe => ({
          id: recipe.id,
          dispenseType: recipe.dispenseType,
          finalConcentration: recipe.finalConcentration,
          finalSource: recipe.finalSource,
          liquidType: recipe.liquidType,
          stockConcentration: recipe.stockConcentration,
          tipWashing: recipe.tipWashing,
          unit: recipe.unit,
        })),
      })
    )

    localStorage.setItem(
      LOCAL_KEY_STORAGE.RECIPE_STEPS,
      JSON.stringify(allMasterMixesWithRecipes)
    )
  }

  // const experimentalPlanDataLocalStorage = {
  //   experimentalPlanId:
  // }
  // commonExperimentalPlan = {
  //   nameOfExperimentalPlan: experimentalPlanData[0].nameOfExperimentalPlan,
  // }

  return (
    <div>
      <ViewExperimentalPlanTable
        data={experimentalPlanData}
        handleEditExperimentalPlan={handleEditExperimentalPlan}
      />

      {experimentalPlanData.length > 0 && (
        <>
          <EditMastermixButton handleEditMastermix={handleCreateMasterMix} />
          <RecipesTable
            data={experimentalPlanData[0].masterMixes}
            handleEditMasterMix={handleEditMasterMix}
            // handleDeleteMasterMix={this.handleDeleteMasterMix}
            // handleCopyMasterMix={this.handleCopyMasterMix}
          />
          <Button
            variant='contained'
            color='success'
            size='medium'
            startIcon={<DownloadIcon />}
            component={Link}
            to='/result'
          >
            {'Generate Worklist Files'}
          </Button>
        </>
      )}

      {/** Display dialog form that allows user to input experimental data */}
      {openExperimentalPlanForm && (
        <CreateNewExperiment
          openExperimentalPlanForm={openExperimentalPlanForm}
          data={experimentalPlanData}
          handleCloseExperimentalForm={handleCloseExperimentalForm}
          // handleFinishExperimentalPlanForm={
          //   this.handleFinishExperimentalPlanForm
          // }
          isEditForm={isEditExperimentalPlanForm}
          // saveEditedForm={this.saveEditedExperimentalPlan}

          nameOfExperimentalPlan={
            experimentalPlanData[0].nameOfExperimentalPlan
          }
          masterMixVolumnPerReaction={
            experimentalPlanData[0].masterMixVolumnPerReaction
          }
          sampleVolumnPerReaction={
            experimentalPlanData[0].sampleVolumnPerReaction
          }
          numOfSampleConcentrations={
            experimentalPlanData[0].numOfSampleConcentrations
          }
          numOfTechnicalReplicates={
            experimentalPlanData[0].numOfTechnicalReplicates
          }
          pcrPlateSize={experimentalPlanData[0].pcrPlateSize}
          rowId={params.experimentId}
        />
      )}

      {/** Display dialog form that allows user to input mastermix data */}
      {openInputForm && (
        <AddNewStep
          openInputForm={openInputForm}
          isEditForm={isEditForm}
          nameOfMastermix={nameOfMastermix}
          masterMixes={experimentalPlanData[0].masterMixes}
          recipeForEachMasterMix={recipes}
          handleNext={handleNext}
          handleBack={handleBack}
          handleReset={handleReset}
          activeStep={activeStep}
          goToStep={goToStep}
          handleCloseForm={handleCloseForm}
          handleFinishForm={handleFinishForm}
          rowId={rowId}
          saveEditedForm={saveEditedForm}
          experimentalPlanData={experimentalPlanData}
          setExperimentalPlanData={setExperimentalPlanData}
        />
      )}
    </div>
  )
}
export default ExperimentPage
