import React from 'react'

const Stepper = ({ currentStep }) => {
  const steps = [
    'Details',
    'Mastermix name',
    'Mastermix details',
    'Worklist files',
  ]

  const checkIcon = (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4 8L6.66667 10.6667L12 5.33333'
        stroke='white'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )

  return (
    <div className='flex items-center justify-center mx-20 mt-6 pb-10'>
      {steps.map((step, index) => (
        <React.Fragment key={step}>
          {/* Line before the icon */}
          {index > 0 && (
            <div
              className={`flex-grow ${
                currentStep >= index
                  ? 'bg-[#121314]'
                  : 'border-t-2 border-dashed border-[#121314]'
              }`}
              style={{ height: '2px' }}
            />
          )}

          <div className='-mb-10'>
            <div className='flex flex-col items-center justify-center m-auto gap-1'>
              <div
                className={`flex justify-center items-center w-6 h-6 rounded-full ${
                  currentStep > index
                    ? 'bg-[#121314]'
                    : 'border-2 border-[#121314]'
                }`}
              >
                {currentStep > index ? (
                  checkIcon
                ) : (
                  <div
                    className={`flex justify-center items-center w-full h-full rounded-full ${
                      currentStep === index ? 'bg-[#121314]' : ''
                    }`}
                  >
                    <span
                      className={`font-nunito text-sm font-bold leading-5 ${
                        currentStep === index ? 'text-white' : 'text-[#121314]'
                      }`}
                    >
                      {index + 1}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className='text-[#121314] font-nunito text-sm font-bold leading-5 relative mb-10 text-center'>
              <span
                className={`absolute text-center ${
                  index === 0 ? '-left-3' : '-left-6'
                }`}
              >
                {step}
              </span>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}

export default Stepper
