export const concentrationUnitOptions = [
  '',
  'mg/mL',
  'ug/mL',
  'ng/mL',
  'mM',
  'uM',
  'nM',
  'X',
  'U/uL',
  '%',
]

export const tipWashingOptions = ['', 'Yes', 'No']

export const dispenseTypeOptions = ['', 'Jet_Empty', 'Surface_Empty']

export const liquidTypeOptions = [
  '',
  'water',
  'buffer',
  'primers',
  'enzymes',
  'template',
  'organics',
  'detergent',
  'mastermix',
]
