import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import DialogModal from './DialogModal'
import RADALogoInverted from './RADALogoInverted'

const NavBar = ({ name, role }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const logOut = () => {
    setIsModalVisible(true)
  }

  const onCancel = () => {
    setIsModalVisible(false)
  }

  const onConfirm = () => {
    setIsModalVisible(false)
    window.localStorage.removeItem('token')
    navigate('/login')
  }

  const isActive = path => location.pathname === path

  return (
    <>
      <div className='flex w-full justify-between items-center py-3 px-20 bg-[#f9f9f9]'>
        <a href='/experiments'>
          <RADALogoInverted scale={0.2} />
        </a>
        <div className='flex items-center gap-5'>
          {/* <div className="flex justify-center items-center gap-2.5 py-3 px-4 Sans text-[#121212] text-center font-['nunito leading-6']">
          Home
        </div> */}
          <button
            className={`flex justify-center items-center gap-2.5 py-4 px-4 Sans text-center border-b-2 ${
              isActive('/experiments') || isActive('/')
                ? 'text-[#121212] border-[#121212]'
                : 'text-[#121212] border-transparent'
            }`}
            onClick={() => navigate('/experiments')}
          >
            Experiments
          </button>
          {role === 'admin' && (
            <button
              className={`flex justify-center items-center gap-2.5 py-4 px-4 Sans text-center border-b-2 ${
                isActive('/roles-settings')
                  ? 'text-[#121212] border-[#121212]'
                  : 'text-[#121212] border-transparent'
              }`}
              onClick={() => navigate('/roles-settings')}
            >
              Settings
            </button>
          )}

          <button
            className='flex justify-center items-center gap-2.5 py-3 px-4 Sans text-[#121212] text-center font-nunito leading-6 border-b-2 border-transparent'
            onClick={logOut}
          >
            Log out
          </button>
          <div className='flex items-center gap-2 '>
            <div className='flex items-start gap-2.5 p-2 rounded-[1.25rem] border-2 border-[#121314] bg-white'>
              <svg
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle cx={12} cy={7} r={5} fill='#0E1420' />
                <path
                  d='M4 18.8C4 16.149 6.14903 14 8.8 14H15.2C17.851 14 20 16.149 20 18.8C20 20.5673 18.5673 22 16.8 22H7.2C5.43269 22 4 20.5673 4 18.8Z'
                  fill='#0E1420'
                />
              </svg>
            </div>
            <div className='flex flex-col items-start'>
              <div className="Sans text-[#121212] font-['nunito text-sm font-bold leading-5']">
                {name}
              </div>
              <div className="Sans text-[#121212] font-['nunito text-xs font-semibold leading-[normal]']">
                {role}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogModal
        isOpen={isModalVisible}
        title={'Log out'}
        message={'Are you sure you want to log out?'}
        onCancel={onCancel}
        onContinue={onConfirm}
      />
    </>
  )
}

export default NavBar
