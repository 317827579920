import React from 'react'
import CreateNewIcon from './icons/CreateNewIcon'
import ImportIcon from './icons/ImportIcon'
import { useNavigate } from 'react-router-dom'
import { SPECIAL_CHAR_FILE } from '../../config/Configurations'
import { createExperimentRequest } from '../../api/experiments.api'
import {
  createMastermixRequest,
  updateRecipesRequest,
} from '../../api/mastermix.api'
import { toast } from 'sonner'

function NewExperimentalPlanMenu() {
  const navigate = useNavigate()
  const handleCreateNewExperimentalPlan = () => {
    navigate('/create-new-experiment')
  }

  const importExperimentFile = async e => {
    e.preventDefault()
    const fileReader = new FileReader()
    fileReader.onload = async e => {
      const inputData = e.target.result
      const splitData = inputData.split(SPECIAL_CHAR_FILE)
      const experimentalData = JSON.parse(splitData[1].replace(/[\n\r]/g, ''))
      const mastermixData = JSON.parse(splitData[2].replace(/[\n\r]/g, ''))

      const experimentalPlanPayload = {
        nameOfExperimentalPlan: experimentalData[0].nameOfExperimentalPlan,
        numOfSampleConcentrations:
          experimentalData[0].numOfSampleConcentrations,
        numOfTechnicalReplicates: experimentalData[0].numOfTechnicalReplicates,
        mastermixVolumePerReaction:
          experimentalData[0].masterMixVolumnPerReaction,
        sampleVolumePerReaction: experimentalData[0].sampleVolumnPerReaction,
        pcrPlateSize: experimentalData[0].pcrPlateSize,
      }

      const newExperiment = await createExperimentRequest(
        experimentalPlanPayload
      )

      for (const mastermix of mastermixData) {
        const newMastermix = await createMastermixRequest(
          newExperiment.data.id,
          {
            nameOfMasterMix: mastermix.nameOfMasterMix,
          }
        )
        await updateRecipesRequest(newMastermix.data.id, {
          recipes: mastermix.recipeForEachMasterMix,
        })
      }
      toast.success('Experimental plan imported successfully')
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }
    fileReader.readAsText(e.target.files[0])
  }

  return (
    <div className='relative'>
      <div className='absolute py-2 px-0 rounded-xl border border-[#e2e2e2] bg-white right-4 top-6'>
        <div className='flex items-center px-3 hover:bg-coolGray whitespace-nowrap'>
          <CreateNewIcon />
          <button
            className='Sans text-[#09090a] font-nunito text-sm leading-5 py-2 px-3'
            onClick={handleCreateNewExperimentalPlan}
          >
            Create new experimental plan
          </button>
        </div>
        <div className='flex items-center px-3 hover:bg-coolGray bg-white whitespace-nowrap mt-4'>
          <ImportIcon />
          <label className='Sans text-[#09090a] font-nunito text-sm leading-5 py-2 px-3 cursor-pointer'>
            Import existing experimental plan
            <input
              id='fileInput'
              className='hidden'
              accept='.txt'
              multiple
              type='file'
              onChange={e => importExperimentFile(e)}
            />
          </label>
        </div>
      </div>
    </div>
  )
}

export default NewExperimentalPlanMenu
