import React from 'react'
import CreateNewIcon from './icons/CreateNewIcon'
import ResetIcon from './icons/ResetIcon'
import FileIcon from './icons/FileIcon'

const MastermixTopButtons = ({ addReagent, handleResetReagents }) => (
  <div>
    <div className='flex flex-shrink-0 justify-end items-center gap-3 w-[618.5px]'>
      <div className='flex items-center gap-2 py-2 pl-3 pr-4 rounded-lg'>
        <ResetIcon />
        <button
          onClick={handleResetReagents}
          className='Sans text-[#58595c] text-center font-nunito font-bold leading-6'
        >
          Reset
        </button>
      </div>

      <FileIcon />

      <div className='flex items-center gap-2 py-2 pl-3 pr-4 rounded-lg'>
        <svg
          width={24}
          height={24}
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M16 21H8C6.34315 21 5 19.6569 5 18V9.65685C5 8.86121 5.31607 8.09814 5.87868 7.53553L9.53553 3.87868C10.0981 3.31607 10.8612 3 11.6569 3H16C17.6569 3 19 4.34315 19 6V18C19 19.6569 17.6569 21 16 21Z'
            stroke='#58595C'
            strokeWidth={2}
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <mask id='path-2-inside-1_1_1136' fill='white'>
            <rect x={8} y={12} width={8} height={6} rx={1} />
          </mask>
          <rect
            x={8}
            y={12}
            width={8}
            height={6}
            rx={1}
            stroke='#58595C'
            strokeWidth={4}
            mask='url(#path-2-inside-1_1_1136)'
          />
          <rect
            x='10.5'
            y='8.5'
            width={5}
            height={1}
            rx='0.5'
            stroke='#58595C'
          />
        </svg>
        <div className='Sans text-[#58595c] text-center font-nunito font-bold leading-6'>
          Templates
        </div>
      </div>
      <svg
        width={2}
        height={18}
        viewBox='0 0 2 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1 1V17'
          stroke='#C3C3C4'
          strokeWidth={2}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
      <div className='flex items-center gap-2 py-2 pl-3 pr-4 rounded-lg'>
        <CreateNewIcon />
        <button
          onClick={addReagent}
          className='Sans text-[#58595c] text-center font-nunito font-bold leading-6'
        >
          Add reagent
        </button>
      </div>
    </div>
  </div>
)

export default MastermixTopButtons
