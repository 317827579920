export const FileIcon = () => {
  return (
    <svg
      width={2}
      height={18}
      viewBox='0 0 2 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 1V17'
        stroke='#C3C3C4'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default FileIcon
