const FormHeader = ({ title, description }) => (
  <div className='flex flex-col justify-between items-start self-stretch h-14'>
    <div className='flex flex-col items-start gap-4 self-stretch'>
      <div className='Sans text-[#505050] text-center font-nunito text-2xl font-bold leading-5'>
        {title}
      </div>
      <div className='Sans text-black font-nunito leading-5'>{description}</div>
    </div>
  </div>
)

export default FormHeader
