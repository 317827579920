export const EmailIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12' cy='12' r='4' stroke='black' strokeWidth='2' />
      <path
        d='M14 14.5L14.5251 15.0251C15.892 16.392 18.108 16.392 19.4749 15.0251V15.0251C20.1313 14.3687 20.5 13.4785 20.5 12.5503V11.5C20.5 7.08172 16.9183 3.5 12.5 3.5H11C6.58172 3.5 3 7.08172 3 11.5V13C3 17.4183 6.58172 21 11 21H17'
        stroke='black'
        strokeWidth='2'
      />
    </svg>
  )
}
