import React, { useEffect } from 'react'
import InfoTooltipIcon from './icons/InfoTooltipIcon' // Assuming this path is correct
import Dropdown from './Dropdown'
import DeleteIcon from './icons/DeleteIcon'
import {
  concentrationUnitOptions,
  tipWashingOptions,
  liquidTypeOptions,
  dispenseTypeOptions,
} from '../../constants'

function MastermixTable({ reagents, setReagents, setIsError, setConcentrationError }) {
  const headers = [
    {
      title: 'Source',
      tooltip:
        'The source is the name of the liquid. Examples of “source” include Water, Polymerase, dNTPs, etc.',
    },
    {
      title: 'Concentration unit',
      tooltip:
        'The concentration unit needs to be the same for both the stock and final concentrations loaded',
    },
    {
      title: 'Final concentration',
      tooltip:
        'The final concentration of each respective reagent in the mastermix. Must be smaller than the stock concentration',
    },
    {
      title: 'Stock Concentration',
      tooltip:
        'The starting concentration of each respective reagent in the mastermix. Must be larger than the final concentration',
    },
    {
      title: 'Tip washing',
      tooltip:
        'Tip washing is recommended for reagents that are highly viscous, such as enzymes in glycerol. By selecting “Yes” to tip washing, the machine will pipette an additional 3 times on dispense to get all of the reagent',
    },
    {
      title: 'Liquid type',
      tooltip:
        'Liquid classes have been optimized for each liquid type that may be used, from viscous liquids to organics. Select the liquid type that best suits the source reagent',
    },
    {
      title: 'Dispense type',
      tooltip:
        ' There are two dispense options, Surface Empty and Jet Empty. Surface Empty will deliver the solution just below the surface of the liquid already in the well. Jet Empty will deliver the solution above the well. Surface Empty is recommended for small volumes and viscous reagents',
    },
    { title: 'Actions', tooltip: '' },
  ]

  const handleDeleteReagent = index => {
    const updatedReagents = reagents.filter((_, i) => i !== index)
    setReagents(updatedReagents)
  }

  const handleRecipesChange = (index, field, value) => {
    const updatedReagents = reagents.map((reagent, i) =>
      i === index ? { ...reagent, [field]: value } : reagent
    )

    setReagents(updatedReagents)
  }

  useEffect(() => {
    const hasInvalidStockConcentration = reagents.some(reagent => {
      return (
        reagent.stockConcentration &&
        reagent.finalConcentration &&
        Number(reagent.stockConcentration) <= Number(reagent.finalConcentration)
      )
    })
    if (hasInvalidStockConcentration) {
      setIsError(true)
    } else {
      setIsError(false)
    }

    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reagents.map(reagent => JSON.stringify(reagent)), reagents, setIsError])

  return (
    <div className='max-h-72 overflow-y-auto'>
      <table className='divide-y divide-gray-200 table-fixed w-full pb-4 overflow-hidden'>
        <thead className='bg-gray-50 sticky top-0 z-10'>
          <tr>
            {headers.map(({ title, tooltip }, index) => (
              <th
                key={index}
                scope='col'
                className={`px-2 text-center align-middle text-xs font-medium text-gray-500 ${
                  index <= 7 && 'w-[12%]'
                } ${index === 7 && 'w-[16%]'}'}`}
              >
                <div className='flex items-center justify-center gap-1'>
                  {title}
                  {title !== 'Actions' && (
                    <InfoTooltipIcon tooltipMessage={tooltip} />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {reagents.map((reagent, rowIndex) => (
            <tr key={rowIndex} className={`${rowIndex === 0 && 'mt-50'}`}>
              {headers.map((header, colIndex) => (
                <td
                  key={colIndex}
                  className={`justify-center py-1 whitespace-nowrap text-sm font-medium text-gray-900 ${
                    rowIndex === 0 && 'pt-2'
                  }`}
                >
                  {colIndex === 0 && (
                    <input
                      type='text'
                      value={reagent.finalSource || ''}
                      placeholder='Enter value'
                      onChange={e =>
                        handleRecipesChange(
                          rowIndex,
                          'finalSource',
                          e.target.value
                        )
                      }
                      className='flex flex-shrink-0 items-center gap-3 py-1 px-4 w-full rounded-lg bg-[#f2f2f3] Sans text-[#1e1e1e] text-center font-nunito text-sm font-semibold leading-5'
                    />
                  )}
                  {colIndex === 1 && (
                    <Dropdown
                      options={concentrationUnitOptions}
                      value={reagent.unit}
                      onChange={e =>
                        handleRecipesChange(rowIndex, 'unit', e.target.value)
                      }
                      placeholder='Select'
                    />
                  )}
                  {colIndex === 2 && (
                    <input
                      type='number'
                      value={reagent.finalConcentration || ''}
                      placeholder='Enter value'
                      onChange={e =>
                        handleRecipesChange(
                          rowIndex,
                          'finalConcentration',
                          e.target.value
                        )
                      }
                      className='flex flex-shrink-0 items-center gap-3 py-1 px-4 w-full rounded-lg bg-[#f2f2f3] Sans text-[#1e1e1e] text-center font-nunito text-sm font-semibold leading-5'
                    />
                  )}
                  {colIndex === 3 && (
                    <input
                      type='number'
                      value={reagent.stockConcentration || ''}
                      placeholder='Enter value'
                      onChange={e =>
                        handleRecipesChange(
                          rowIndex,
                          'stockConcentration',
                          e.target.value
                        )
                      }
                      className='flex flex-shrink-0 items-center gap-3 py-1 px-4 w-full rounded-lg bg-[#f2f2f3] Sans text-[#1e1e1e] text-center font-nunito text-sm font-semibold leading-5'
                    />
                  )}
                  {colIndex === 4 && (
                    <Dropdown
                      options={tipWashingOptions}
                      value={reagent.tipWashing}
                      onChange={e =>
                        handleRecipesChange(
                          rowIndex,
                          'tipWashing',
                          e.target.value
                        )
                      }
                    />
                  )}
                  {colIndex === 5 && (
                    <Dropdown
                      options={liquidTypeOptions}
                      value={reagent.liquidType}
                      onChange={e =>
                        handleRecipesChange(
                          rowIndex,
                          'liquidType',
                          e.target.value
                        )
                      }
                    />
                  )}
                  {colIndex === 6 && (
                    <Dropdown
                      options={dispenseTypeOptions}
                      value={reagent.dispenseType}
                      onChange={e =>
                        handleRecipesChange(
                          rowIndex,
                          'dispenseType',
                          e.target.value
                        )
                      }
                    />
                  )}
                  {colIndex === headers.length - 1 && (
                    <div className='flex justify-center items-center'>
                      <button onClick={() => handleDeleteReagent(rowIndex)}>
                        <DeleteIcon />
                      </button>
                    </div>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default MastermixTable
