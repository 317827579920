import React from 'react'

function CSVFileIcon() {
  return (
    <div className='w-20 h-20'>
      <svg
        width='80'
        height='80'
        viewBox='0 0 80 80'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17 73H60C61.1046 73 62 72.1046 62 71V9C62 7.89543 61.1046 7 60 7H31.1846C30.6428 7 30.1241 7.21984 29.7473 7.60923L15.5627 22.2681C15.2018 22.6411 15 23.1398 15 23.6589V71C15 72.1046 15.8954 73 17 73Z'
          fill='url(#paint0_linear_1_4318)'
        />
        <path
          d='M54.4283 38.2197H58.5762L61.4639 47.4924L64.308 38.2197H68.3333L63.5729 51.1048H59.2762L54.4283 38.2197Z'
          fill='black'
        />
        <path
          d='M42.0546 46.842L45.8262 46.6047C45.9079 47.22 46.0742 47.6887 46.325 48.011C46.7334 48.5325 47.3168 48.7932 48.0752 48.7932C48.6411 48.7932 49.0757 48.6614 49.3791 48.3977C49.6883 48.1282 49.8429 47.8176 49.8429 47.4661C49.8429 47.1321 49.697 46.8332 49.4053 46.5695C49.1136 46.3059 48.4369 46.0568 47.3751 45.8225C45.6366 45.4299 44.3969 44.9084 43.656 44.258C42.9093 43.6076 42.5359 42.7784 42.5359 41.7706C42.5359 41.1085 42.7255 40.4844 43.1047 39.8985C43.4898 39.3067 44.0644 38.8438 44.8286 38.5098C45.5987 38.1699 46.6517 38 47.9877 38C49.627 38 50.8755 38.3076 51.733 38.9229C52.5964 39.5323 53.1098 40.5049 53.2732 41.8409L49.5366 42.0606C49.4374 41.4806 49.2274 41.0587 48.9065 40.795C48.5915 40.5313 48.1539 40.3995 47.5939 40.3995C47.133 40.3995 46.7859 40.4991 46.5525 40.6983C46.3192 40.8917 46.2025 41.129 46.2025 41.4102C46.2025 41.6153 46.2988 41.7999 46.4913 41.964C46.678 42.1339 47.1214 42.2921 47.8214 42.4386C49.5541 42.8136 50.7938 43.1945 51.5405 43.5812C52.2931 43.9621 52.8386 44.4367 53.1769 45.0051C53.5211 45.5734 53.6932 46.2092 53.6932 46.9123C53.6932 47.7385 53.4657 48.5003 53.0107 49.1975C52.5556 49.8948 51.9197 50.4251 51.103 50.7884C50.2862 51.1458 49.2566 51.3245 48.0139 51.3245C45.8321 51.3245 44.3211 50.9027 43.481 50.0589C42.6409 49.2151 42.1655 48.1428 42.0546 46.842Z'
          fill='black'
        />
        <path
          d='M37.3379 45.84L40.812 46.8947C40.5787 47.8733 40.2111 48.6907 39.7094 49.347C39.2077 50.0032 38.5835 50.4984 37.8367 50.8323C37.0958 51.1663 36.1507 51.3333 35.0015 51.3333C33.6072 51.3333 32.4666 51.1312 31.5799 50.7269C30.699 50.3167 29.9377 49.5989 29.2959 48.5735C28.6542 47.5481 28.3333 46.2356 28.3333 44.6359C28.3333 42.503 28.8963 40.8653 30.0223 39.7227C31.154 38.5742 32.7525 38 34.8177 38C36.4337 38 37.7026 38.3281 38.6243 38.9844C39.5519 39.6407 40.2403 40.6485 40.6895 42.0079L37.1892 42.7902C37.0667 42.3976 36.9383 42.1105 36.8041 41.9288C36.5824 41.6241 36.3112 41.3897 35.9903 41.2257C35.6694 41.0616 35.3107 40.9796 34.914 40.9796C34.0155 40.9796 33.3271 41.3429 32.8488 42.0694C32.4871 42.6085 32.3062 43.4552 32.3062 44.6095C32.3062 46.0393 32.5221 47.0207 32.9538 47.5539C33.3855 48.0813 33.9922 48.345 34.7739 48.345C35.5324 48.345 36.1041 48.1311 36.4891 47.7034C36.88 47.2756 37.1629 46.6545 37.3379 45.84Z'
          fill='black'
        />
        <path
          d='M30 24H15V22.8284C15 22.298 15.2107 21.7893 15.5858 21.4142L29.4142 7.58579C29.7893 7.21071 30.298 7 30.8284 7H32V22C32 23.1046 31.1046 24 30 24Z'
          fill='#F2F2F3'
        />
        <defs>
          <linearGradient
            id='paint0_linear_1_4318'
            x1='69'
            y1='71.5'
            x2='88.2622'
            y2='-28.5663'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='#E2E2E2' />
            <stop offset='1' stop-color='#ADAFB5' />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

export default CSVFileIcon
