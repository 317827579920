import './App.css'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom'

import InteractiveInstructionNew from './components/InteractiveInstructionNew'
// import { ExperimentsPage } from './pages/experiments/ExperimentsPage'
import ExperimentsPageNew from './pages/experiments/ExperimentsPageNew'
import ExperimentPage from './pages/experiments/ExperimentPage'
import SigninPage from './pages/auth/SigninPage'
import SignupPage from './pages/auth/SignupPage'
import EmailSentPage from './pages/auth/EmailSentPage'
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage'
import ResetPasswordPage from './pages/auth/ResetPasswordPage'
import ResnedConfirmationEmailPage from './pages/auth/ResendConfirmationEmailPage'
import { Toaster } from 'sonner'
import CreateNewExperiment from './pages/experiments/CreateNewExperiment'
import ConfirmUserPage from './pages/auth/ConfirmUserPage'
import { AuthProvider, useAuth } from './context/AuthContext'
import RolesSettings from './pages/settings/RolesSettings'
import NavBar from './components/ui/NavBar'
import ExperimentDocuments from './components/ui/ExperimentDocuments'
import GenerateWorklistFiles from './pages/GenerateWorklistFiles'
import { useNavigate } from 'react-router-dom'

function App() {
  function ProtectedRoute({ children }) {
    const { isAuthenticated } = useAuth()
    const location = useLocation()
    const navigate = useNavigate()

    if (!isAuthenticated) {
      navigate('/login', { state: { from: location } })
    }

    return children
  }

  const NavBarWrapper = () => {
    const location = useLocation()
    const { name, role } = useAuth()
    const hideOnRoutes = [
      '/login',
      '/register',
      '/forgot-password',
      '/reset-password',
      '/email-sent',
      '/roles-settings',
      '/auth/confirm',
    ]
    if (hideOnRoutes.includes(location.pathname)) {
      return null
    }
    return <NavBar name={name} role={role} />
  }
  return (
    <AuthProvider>
      <Router>
        <div className='App'>
          <NavBarWrapper />
          <Routes>
            <Route
              path='/'
              element={
                <ProtectedRoute>
                  <ExperimentsPageNew />
                </ProtectedRoute>
              }
            />
            <Route path='/login' element={<SigninPage />} />
            <Route path='/register' element={<SignupPage />} />
            <Route path='/forgot-password' element={<ForgotPasswordPage />} />
            <Route path='/resend-confirmation-email' element={<ResnedConfirmationEmailPage />} />
            <Route path='/reset-password' element={<ResetPasswordPage />} />
            <Route path='/email-sent' element={<EmailSentPage />} />
            {/* <Route path='/result' element={<ResultOutput />} /> */}
            {/* <Route path='/experiments' element={<ExperimentsPage />} /> */}
            <Route
              path='/experiments'
              element={
                <ProtectedRoute>
                  <ExperimentsPageNew />
                </ProtectedRoute>
              }
            />
            <Route
              path='/experiments/:experimentId'
              element={
                <ProtectedRoute>
                  <ExperimentsPageNew />
                </ProtectedRoute>
              }
            />
            <Route
              path='/experiments/:experimentId/documents'
              element={
                <ProtectedRoute>
                  <ExperimentDocuments />
                </ProtectedRoute>
              }
            />
            <Route
              path='/roles-settings'
              element={
                <ProtectedRoute>
                  <RolesSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path='/create-new-experiment'
              element={
                <ProtectedRoute>
                  <CreateNewExperiment />
                </ProtectedRoute>
              }
            />
            <Route
              path='/experiments/:experimentId'
              element={
                <ProtectedRoute>
                  <ExperimentPage />
                </ProtectedRoute>
              }
            />

            <Route
              path='/auth/confirm/:confirmToken'
              element={<ConfirmUserPage />}
            />

            <Route
              path='/experiments/:experimentId/worklist-files'
              element={
                <ProtectedRoute>
                  <GenerateWorklistFiles />
                </ProtectedRoute>
              }
            />

            <Route
              path='/experiments/:experimentId/instructions'
              element={
                <ProtectedRoute>
                  <InteractiveInstructionNew />
                </ProtectedRoute>
              }
            />

            {/* <Route path='/experiments/:experimentId' element={<MainWorkListPage/>} /> */}

            {/* <Route path='/mastermixes' element={<MainWorkListPage />} /> */}

            {/* If any route mismatches the upper route endpoints then, redirect triggers 
          and redirects app to home component with to="/" */}
            <Route path='*' element={<Navigate to='/' replace />} />
          </Routes>
          <Toaster />
        </div>
      </Router>
    </AuthProvider>
  )
}

export default App
