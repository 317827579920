import React from 'react'
import LeftArrowIcon from './icons/LeftArrowIcon'
import RightArrowIcon from './icons/RightArrowIcon'
import { useNavigate } from 'react-router-dom'

const FooterNavigator = ({
  isContinueEnabled,
  handleContinue,
  handleGoBack,
}) => {
  const navigate = useNavigate()

  const handleCancel = () => {
    navigate('/experiments')
  }

  return (
    <div className='absolute bottom-0 w-full flex justify-between items-center pt-3 pb-5 px-20 rounded-bl-xl rounded-br-xl border-t border-t-[#434447] bg-white'>
      <button
        onClick={handleGoBack}
        className='flex items-center gap-2 py-2 pl-4 pr-5 rounded-lg border border-[#58595c] Sans text-[#58595c] text-center font-nunito font-bold leading-6'
      >
        <LeftArrowIcon />
        Go back
      </button>

      <div className='flex justify-end items-center gap-3'>
        <button
          onClick={handleCancel}
          className="flex items-center gap-2 py-2 px-5 rounded-lg border border-[#58595c] Sans text-[#58595c] text-center font-['nunito font-bold leading-6']"
        >
          Cancel
        </button>
        <button
          disabled={!isContinueEnabled}
          onClick={handleContinue}
          className={`flex items-center justify-center Sans text-center text-white font-nunito w-auto font-bold gap-2 py-2 pl-5 pr-4 leading-6 rounded-lg ${
            isContinueEnabled
              ? 'bg-[#121314] text-white'
              : 'text-[#58595c] bg-[#d9d9da]'
          }`}
        >
          Continue
          <RightArrowIcon />
        </button>
      </div>
    </div>
  )
}

export default FooterNavigator
