import React, { useEffect, useState } from 'react'
import Stepper from '../../components/ui/Stepper'
import InputExperiment from '../../components/ui/InputExperiment'
import FooterNavigator from '../../components/ui/FooterNavigator'
import MastermixTopButtons from '../../components/ui/MastermixTopButtons'
import MastermixTable from '../../components/ui/MastermixTable'
import MastermixTableEdit from '../../components/ui/MastermixTableEdit'
import CreateNewIcon from '../../components/ui/icons/CreateNewIcon'

import {
  createExperimentRequest,
  getExperimentRequest,
  updateExperimentRequest,
} from '../../api/experiments.api'
import {
  createRecipesRequest,
  createMastermixRequest,
  updateRecipesRequest,
} from '../../api/mastermix.api'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import GenerateWorklistFilesComponent from '../../components/ui/GenerateWorklistFilesComponent'
import { toast } from 'sonner'
import DialogModal from '../../components/ui/DialogModal'

const CreateNewExperiment = () => {
  const [experimentDetails, setExperimentDetails] = useState({
    nameOfExperimentalPlan: '',
    numOfSampleConcentrations: '',
    numOfTechnicalReplicates: '',
    mastermixVolumePerReaction: '',
    sampleVolumePerReaction: '',
    pcrPlateSize: '',
  })
  const [experimentId, setExperimentId] = useState('')
  const [mastermixDetails, setMastermixDetails] = useState({
    nameOfMasterMix: '',
  })
  const [mastermixId, setMastermixId] = useState('')
  const [mastermixData, setMastermixData] = useState([])
  const [reagents, setReagents] = useState([
    {
      finalSource: '',
      unit: '',
      finalConcentration: '',
      tipWashing: '',
      stockConcentration: '',
      liquidType: '',
      dispenseType: '',
    },
  ])
  const [isError, setIsError] = useState(false)
  const [concentrationError, setConcentrationError] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [step, setStep] = useState(0)
  const {
    register,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onChange' })
  const navigate = useNavigate()

  const [combinedVolumeError, setCombinedVolumeError] = useState(false)

  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const mastermixVolume = watch('mastermixVolumePerReaction')
  const sampleVolume = watch('sampleVolumePerReaction')

  useEffect(() => {
    // Convert to numbers and sum
    const totalVolume = Number(mastermixVolume) + Number(sampleVolume)

    // Check if the combined volume exceeds 150 and update state accordingly
    if (totalVolume > 150) {
      setCombinedVolumeError(true)
    } else {
      setCombinedVolumeError(false)
    }
  }, [mastermixVolume, sampleVolume])

  const addReagent = () => {
    setReagents(prevReagents => [
      ...prevReagents,
      {
        finalSource: '',
        unit: '',
        finalConcentration: '',
        tipWashing: '',
        stockConcentration: '',
        liquidType: '',
        dispenseType: '',
      },
    ])
  }

  const handleContinue = async () => {
    try {
      switch (step) {
        case 0:
          setSubmitting(true)
          if (!experimentId) {
            const response = await createExperimentRequest(experimentDetails)
            setExperimentId(response.data.id)
            toast.success('Experiment created successfully', { duration: 2000 })
          } else {
            await updateExperimentRequest(experimentId, experimentDetails)
          }
          setSubmitting(false)
          break
        case 1:
          setSubmitting(true)
          if (!mastermixId) {
            const mastermixCreated = await handleAddMastermix(
              experimentId,
              mastermixDetails.nameOfMasterMix,
              false
            )
            setMastermixId(mastermixCreated.data[0].mastermixId)
            toast.success('Mastermix created successfully', { duration: 2000 })
          }
          setSubmitting(false)
          break
        case 2:
          setSubmitting(true)
          await createRecipesRequest(mastermixId, reagents)

          if (step === 2 && experimentId) {
            try {
              const response = await getExperimentRequest(experimentId)
              const { masterMixes } = response.data

              const updatedMastermixesToFile = masterMixes.map(mmix => ({
                nameOfMasterMix: mmix.nameOfMasterMix,
                id: mmix.id,
                recipeForEachMasterMix: mmix.recipes,
              }))

              setMastermixData(updatedMastermixesToFile)

              toast.success('Mastermix updated successfully', {
                duration: 2000,
              })
            } catch (error) {
              console.error('Failed to fetch mastermix and recipes:', error)
            }
          }

          setSubmitting(false)
          break
        // case 3:

        default:
          break
      }
      setStep(step + 1)
    } catch (error) {
      console.error('Error processing request:', error)
    }
  }

  const handleGoBack = () => {
    if (step > 0) {
      setStep(step - 1)
    } else {
      navigate('/experiments')
    }
  }

  const updateExperimentDetail = (key, value) => {
    setExperimentDetails(prev => ({ ...prev, [key]: value }))
  }

  const updateMastermixDetail = value => {
    setMastermixDetails({ nameOfMasterMix: value })
  }

  useEffect(() => {
    const isValid = reagents.every(reagent => {
      return (
        Number(reagent.finalConcentration) >= 0 &&
        Number(reagent.stockConcentration) >= 0
      )
    })

    if (isValid) {
      setReagents(reagents)
      setConcentrationError(false)
    } else {
      setConcentrationError(true)
    }
  }, [reagents])

  const isContinueEnabled = () => {
    if (step === 0) {
      return (
        !Object.keys(errors).length > 0 && !combinedVolumeError && !submitting
      )
    }
    if (step === 1) {
      return (
        mastermixDetails.nameOfMasterMix.length <= 50 &&
        mastermixDetails.nameOfMasterMix !== '' && 
        !submitting
      )
    }
    if (step === 2) {
      if (reagents.length > 0 && !submitting) {
        return (
          reagents.every(reagent =>
            Object.values(reagent).every(value => value !== '')
          ) &&
          !isError &&
          !concentrationError
        )
      } else {
        return false
      }
    }

    if (step > 3) {
      return false
    }
    return true
  }

  const handleResetReagents = () => {
    // show modal
    setTitle('Reset reagents')
    setMessage('Are you sure you want to reset all reagents?')
    setIsModalOpen(true)
  }

  const onContinue = () => {
    setReagents(
      Array.from({ length: reagents.length }, () => ({
        finalSource: '',
        unit: '',
        finalConcentration: '',
        tipWashing: '',
        stockConcentration: '',
        liquidType: '',
        dispenseType: '',
      }))
    )
    setIsModalOpen(false)
  }

  const onCancel = () => {
    setIsModalOpen(false)
  }

  const handleAddMastermix = async (
    experimentId,
    mastermixName = 'New Mastermix',
    executeSetReagents = true // Default to true
  ) => {
    try {
      const newMastermix = await createMastermixRequest(experimentId, {
        nameOfMasterMix: mastermixName,
      })
      const updatedMastermix = await updateRecipesRequest(
        newMastermix.data.id,
        {
          recipes: [
            {
              finalSource: '',
              unit: '',
              finalConcentration: 0,
              tipWashing: '',
              stockConcentration: 0,
              liquidType: '',
              dispenseType: '',
            },
          ],
        }
      )

      if (executeSetReagents && mastermixName !== 'New Mastermix') {
        setReagents(prev => [
          ...prev,
          {
            finalSource: '',
            unit: '',
            finalConcentration: 0,
            tipWashing: '',
            stockConcentration: 0,
            liquidType: '',
            dispenseType: '',
          },
        ])
      }

      return updatedMastermix
      // setExperimentDetails(prev => ({
      //   ...prev,
      //   masterMixes: [...prev.masterMixes, newMastermix.data],
      // }))
    } catch (error) {
      console.error('Failed to add mastermix or update recipes:', error)
    }
  }

  console.log('reagents: ', reagents)

  return (
    <div className='flex flex-col justify-between'>
      <div className='inline-flex flex-col items-start mt-6 gap-4 ml-20'>
        <div className='Sans text-[#505050] font-nunito text-2xl font-bold leading-5'>
          Create new experimental plan
        </div>
        <div className='Sans text-[#121314] font-nunito leading-6'>
          Follow the steps and instructions to create and complete the
          experiment
        </div>
      </div>
      <Stepper currentStep={step} />

      {/* Experimental plan Details */}
      {step === 0 && (
        <>
          <div className='flex flex-col justify-between max-h-[42vh]'>
            <form className='flex flex-col mx-20 mt-7 p-6 gap-10 rounded-xl border border-[#e2e2e2] overflow-auto'>
              <div className='flex flex-col items-start self-stretch'>
                <div className='Sans text-[#434447] font-nunito text-4xl font-bold leading-[52px]'>
                  Experimental plan details
                </div>
                <div className='Sans text-[#121314] font-nunito leading-6'>
                  Fill out the details and notice that all fields are required
                </div>
              </div>
              <div className='flex items-start gap-5 self-stretch'>
                <InputExperiment
                  placeholder='max 50 characters'
                  label='Name of experimental plan'
                  tooltipMessage='Recommend to choose a name that is both descriptive and short'
                  {...register('nameOfExperimentalPlan', {
                    required: 'This field is required',
                    maxLength: {
                      value: 50,
                      message: 'Name cannot be more than 50 characters',
                    },
                    onChange: e => {
                      updateExperimentDetail(
                        'nameOfExperimentalPlan',
                        e.target.value
                      )
                    },
                  })}
                  error={errors.nameOfExperimentalPlan}
                />
                <InputExperiment
                  placeholder='[1 - 100]'
                  label='Number of samples'
                  tooltipMessage='Number of samples to be included in the experiment, this number should include controls as well'
                  type='number'
                  {...register('numOfSampleConcentrations', {
                    required: 'This field is required',
                    min: {
                      value: 1,
                      message: 'Number of samples cannot be less than 1',
                    },
                    max: {
                      value: 100,
                      message: 'Number of samples cannot be more than 100',
                    },
                    onChange: e => {
                      updateExperimentDetail(
                        'numOfSampleConcentrations',
                        e.target.value
                      )
                    },
                  })}
                  error={errors.numOfSampleConcentrations}
                />
                <InputExperiment
                  placeholder='[1 - 50]'
                  label='Number of technical replicates'
                  tooltipMessage='Number of replicates that will be run for each condition tested'
                  type='number'
                  {...register('numOfTechnicalReplicates', {
                    required: 'This field is required',
                    min: {
                      value: 1,
                      message:
                        'Number of technical replicates cannot be less than 1',
                    },
                    max: {
                      value: 50,
                      message:
                        'Number of technical replicates cannot be more than 50',
                    },

                    onChange: e => {
                      updateExperimentDetail(
                        'numOfTechnicalReplicates',
                        e.target.value
                      )
                    },
                  })}
                  error={errors.numOfTechnicalReplicates}
                />
              </div>
              <div className='flex items-start gap-5 self-stretch'>
                <InputExperiment
                  placeholder='[1 - 200]'
                  label='Mastermix volume per reaction (µL)'
                  tooltipMessage='Volume of mastermix to be added into each well'
                  type='number'
                  {...register('mastermixVolumePerReaction', {
                    required: 'This field is required',
                    min: {
                      value: 1,
                      message:
                        'Mastermix volume per reaction cannot be less than 1',
                    },
                    max: {
                      value: 200,
                      message:
                        'Mastermix volume per reaction cannot be more than 200',
                    },
                    onChange: e => {
                      updateExperimentDetail(
                        'mastermixVolumePerReaction',
                        e.target.value
                      )
                    },
                  })}
                  error={errors.mastermixVolumePerReaction}
                />
                <InputExperiment
                  placeholder='[1 - 50]'
                  label='Sample volume per reaction'
                  tooltipMessage='Volume of sample to be added into each well'
                  type='number'
                  {...register('sampleVolumePerReaction', {
                    required: 'This field is required',
                    min: {
                      value: 1,
                      message:
                        'Sample volume per reaction cannot be less than 1',
                    },
                    max: {
                      value: 50,
                      message:
                        'Sample volume per reaction cannot be more than 50',
                    },
                    onChange: e => {
                      updateExperimentDetail(
                        'sampleVolumePerReaction',
                        e.target.value
                      )
                    },
                  })}
                  error={errors.sampleVolumePerReaction}
                />
                <InputExperiment
                  placeholder='Select PCR Plate Size'
                  label='PCR Plate Size'
                  tooltipMessage=' The system is set up to run either a 96 or 384 well plate. Select which best fits your experiment needs'
                  type='select'
                  options={[
                    { value: '96', label: '96' },
                    { value: '384', label: '384' },
                  ]}
                  {...register('pcrPlateSize', {
                    required: 'This field is required',
                    validate: value =>
                      ['96', '384'].includes(value) || 'Invalid PCR Plate Size',
                  })}
                  error={errors.pcrPlateSize}
                  onChange={e => {
                    updateExperimentDetail('pcrPlateSize', e.target.value)
                  }}
                />
              </div>
            </form>
          </div>
          {combinedVolumeError && (
            <div className='text-red-500 flex flex-col mx-20 mt-4'>
              The combined volume of sample and mastermix per reaction cannot
              exceed 150 µL.
            </div>
          )}
        </>
      )}
      {/* Mastermix name */}
      {step === 1 && (
        <div className='flex flex-col justify-between max-h-[50vh]'>
          <div className='flex flex-col items-start px-20 mx-20 mt-5 p-6 gap-10 rounded-xl border border-[#e2e2e2] overflow-auto'>
            <div className='flex flex-col items-start self-stretch'>
              <div className='Sans text-[#434447] font-nunito text-4xl font-bold leading-[52px]'>
                Mastermix name
              </div>
              <div className='pt-3 Sans text-[#121314] font-nunito leading-6'>
                Fill out the form to continue with mastermix data
              </div>
            </div>
            <div className='w-96'>
              <InputExperiment
                placeholder='Name'
                tooltipMessage='Enter the name of your Mastermix. Choose a name that clearly identifies its purpose or contents'
                {...register('nameOfMasterMix', {
                  maxLength: {
                    value: 50,
                    message: 'Mastermix name cannot be more than 50 characters',
                  },
                  onChange: e => {
                    updateMastermixDetail(e.target.value)
                  },
                })}
                error={errors.nameOfMasterMix}
              />
            </div>
          </div>
        </div>
      )}
      {/* Mastermix details */}
      {step === 2 && (
        <>
          <div className='flex flex-col justify-between max-h-[39vh]'>
            <div className='flex flex-col pl-20 py-2 mx-20 mt-4 gap-10 rounded-xl border border-[#e2e2e2] overflow-auto'>
              <div className='flex justify-between'>
                <div className='flex flex-col items-start self-stretch'>
                  <div className='Sans text-[#434447] font-nunito text-4xl font-bold leading-[52px]'>
                    Mastermix details
                  </div>
                  <div className='Sans text-[#121314] font-nunito leading-6'>
                    Add reagents to the mastermix as necessary
                  </div>
                </div>
                <MastermixTopButtons
                  addReagent={addReagent}
                  handleResetReagents={handleResetReagents}
                />
              </div>
              <div className='flex flex-col overflow-auto max-h-80'>
                <MastermixTable
                  reagents={reagents}
                  setReagents={setReagents}
                  setIsError={setIsError}
                  setConcentrationError={setConcentrationError}
                />
              </div>
            </div>
          </div>
          {isError && (
            <div className='text-red-500 flex flex-col mx-20 mt-4'>
              Stock concentration should be greater than final concentration
            </div>
          )}
          {concentrationError && (
            <div className='text-red-500 flex flex-col mx-20 mt-4'>
              Concentration values must not be negative
            </div>
          )}
        </>
      )}
      {/* Mastermix Edit */}
      {step === 3 && (
        <>
          <div className='px-20 flex justify-between'>
            <div className='Sans py-3 text-[#434447] font-nunito text-4xl font-bold leading-6'>
              Experimental Plan
            </div>
          </div>
          <div className='flex flex-col  px-20'></div>
          <div className='px-20 flex justify-between'>
            <div className='Sans pb-3 mt-7 text-[#434447] font-nunito text-4xl font-bold leading-6'>
              Mastermix
            </div>
            <button className='flex items-center gap-2 py-2 pl-3 pr-4 rounded-lg'>
              <CreateNewIcon />
              <button
                onClick={() => handleAddMastermix(experimentId)}
                className='Sans text-[#58595c] text-center font-nunito font-bold leading-6'
              >
                Add mastermix
              </button>
            </button>
          </div>
          <div className='flex flex-col px-20 max-h-[20vh]'>
            <div className='flex flex-col overflow-auto'>
              <MastermixTableEdit
                experimentId={experimentId}
                newMastermixesTrigger={reagents}
              />
            </div>
          </div>
        </>
      )}
      {/* Worklist files */}
      {step === 4 && (
        <div className='flex flex-col px-10 max-h-[39vh]'>
          <div className='flex flex-col overflow-x-hidden'>
            <GenerateWorklistFilesComponent
              experiment={[
                {
                  nameOfExperimentalPlan:
                    experimentDetails.nameOfExperimentalPlan,
                  masterMixVolumnPerReaction: parseInt(
                    experimentDetails.mastermixVolumePerReaction
                  ),
                  sampleVolumnPerReaction: parseInt(
                    experimentDetails.sampleVolumePerReaction
                  ),
                  numOfSampleConcentrations: parseInt(
                    experimentDetails.numOfSampleConcentrations
                  ),
                  numOfTechnicalReplicates: parseInt(
                    experimentDetails.numOfTechnicalReplicates
                  ),
                  pcrPlateSize: parseInt(experimentDetails.pcrPlateSize),
                  experimentalPlanID: experimentId,
                },
              ]}
              mastermixes={mastermixData}
              experimentIdProp={experimentId}
            />
          </div>
        </div>
      )}

      <FooterNavigator
        isContinueEnabled={
          isContinueEnabled() &&
          Object.values(experimentDetails).every(value => value !== '')
        }
        handleContinue={handleContinue}
        handleGoBack={handleGoBack}
      />
      <DialogModal
        isOpen={isModalOpen}
        onCancel={onCancel}
        onContinue={onContinue}
        title={title}
        message={message}
      />
    </div>
  )
}

export default CreateNewExperiment
