import * as yup from 'yup'

export const signupSchema = yup
  .object({
    fullName: yup
      .string()
      .required('Full name is required')
      .matches(
        /^[a-zA-Z\s]+$/,
        'Full name must contain only letters and spaces'
      )
      .max(50, 'Full name must not exceed 50 characters')
      .trim()
      .test(
        'is-valid',
        'Full name must not start or end with a space',
        value => !/^ | $/.test(value)
      ),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .max(50, 'Full name must not exceed 50 characters')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      // at least one number
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(
        /[^a-zA-Z0-9\s]/,
        'Password must contain at least one special character'
      )
      .matches(/^\S*$/, 'Password must not contain spaces'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Please confirm your password'),
    email: yup
      .string()
      .required('Email is required')
      .max(50, 'Full name must not exceed 50 characters')
      .email('Please enter a valid email address')
      .trim(),
  })
  .required()

export const changePasswordSchema = yup.object({
  newPassword: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(
      /[^a-zA-Z0-9\s]/,
      'Password must contain at least one special character'
    )
    .matches(/^\S*$/, 'Password must not contain spaces'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Please confirm your new password'),
})
