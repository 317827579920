import React from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Paper,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const columnHeaderFirstRow = [
  {
    label: 'Recipe For Each Mastermix',
    colSpan: 2,
  },
  {
    label: 'Stock Concentration For Each Source In Mastermix Recipe',
    colSpan: 3,
  },
]

const columnHeaderSecondRow = [
  'Name of Mastermix',
  'Source',
  'Final Concentration',
  'Tip Washing',
  'Stock Concentration',
  'Liquid Type',
  'Dispense Type',
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#022e3e',
    color: theme.palette.common.white,
    padding: 3,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'center',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(even)': {
  //   backgroundColor: theme.palette.action.hover,
  // }
}))

const BodyCell = (label, row) => (
  <StyledTableCell rowSpan={row.recipes.length} sx={styles.tableCell}>
    {label}
  </StyledTableCell>
)

const BodyCellMultiRow = label => (
  <StyledTableCell sx={styles.tableCell}>{label}</StyledTableCell>
)

const styles = {
  paperContainer: {
    justifyContent: 'center',
    width: '95%',
    overflow: 'auto',
    marginTop: 5,
  },
  tableContainer: {
    maxHeight: '55vh',
  },
  tableCell: {
    borderRight: 1,
    borderBottom: '2px solid rgb(212, 212,212)',
  },
}

const ViewAllSteps = ({
  data,
  handleEditMasterMix,
  handleCopyMasterMix,
  handleDeleteMasterMix,
}) => {
  const editRow = rowId => {
    handleEditMasterMix(rowId)
  }

  const copyRow = rowId => {
    handleCopyMasterMix(rowId)
  }

  const deleteRow = rowId => {
    handleDeleteMasterMix(rowId)
  }

  return (
    <Paper elevation={2} sx={styles.paperContainer}>
      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Table
          stickyHeader
          sx={{ minWidth: 500 }}
          padding='none'
          aria-label='data table'
        >
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ borderRight: 1 }} colSpan={2} />
              {columnHeaderFirstRow.map((val, index) => (
                <StyledTableCell
                  colSpan={val.colSpan}
                  key={index}
                  sx={{ borderRight: 1 }}
                >
                  {val.label}
                </StyledTableCell>
              ))}
              <StyledTableCell colSpan={7} />
            </TableRow>
            <TableRow>
              {columnHeaderSecondRow.map((label, index) => (
                <StyledTableCell sx={{ borderRight: 1 }} key={index}>
                  {label}
                </StyledTableCell>
              ))}
              <StyledTableCell colSpan={3}>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(row => {
              if (row.recipes.length === 0) {
                return (
                  <StyledTableRow hover={true} key={row.id}>
                    <StyledTableCell sx={styles.tableCell}>
                      {row.nameOfMasterMix}
                    </StyledTableCell>
                    {/* Empty cells for the other columns */}
                    <StyledTableCell sx={styles.tableCell} />
                    <StyledTableCell sx={styles.tableCell} />
                    <StyledTableCell sx={styles.tableCell} />
                    <StyledTableCell sx={styles.tableCell} />
                    <StyledTableCell sx={styles.tableCell} />
                    <StyledTableCell sx={styles.tableCell} />
                    <StyledTableCell sx={styles.tableCell}>
                      <ModeEditOutlineRoundedIcon
                        sx={{ color: '#1183ca' }}
                        onClick={() => editRow(row.id)}
                      />
                    </StyledTableCell>
                    <StyledTableCell sx={styles.tableCell}>
                      <ContentCopyIcon
                        sx={{ color: '#1183ca' }}
                        onClick={() => copyRow(row.id)}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ ...styles.tableCell, borderRight: 0 }}
                    >
                      <DeleteForeverRoundedIcon
                        sx={{ color: 'red' }}
                        onClick={() => deleteRow(row.id)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )
              } else {
                return row.recipes.map((recipe, i) => (
                  <StyledTableRow hover={true} key={i}>
                    {i === 0 && BodyCell(row.nameOfMasterMix, row)}
                    {BodyCellMultiRow(recipe.finalSource)}
                    {BodyCellMultiRow(recipe.finalConcentration)}
                    {BodyCellMultiRow(recipe.tipWashing)}
                    {BodyCellMultiRow(recipe.stockConcentration)}
                    {BodyCellMultiRow(recipe.liquidType)}
                    {BodyCellMultiRow(recipe.dispenseType)}
                    {i === 0 && (
                      <>
                        <StyledTableCell sx={styles.tableCell}>
                          <ModeEditOutlineRoundedIcon
                            sx={{ color: '#1183ca' }}
                            onClick={() => editRow(row.id)}
                          />
                        </StyledTableCell>
                        <StyledTableCell sx={styles.tableCell}>
                          <ContentCopyIcon
                            sx={{ color: '#1183ca' }}
                            onClick={() => copyRow(row.id)}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ ...styles.tableCell, borderRight: 0 }}
                        >
                          <DeleteForeverRoundedIcon
                            sx={{ color: 'red' }}
                            onClick={() => deleteRow(row.id)}
                          />
                        </StyledTableCell>
                      </>
                    )}
                    {i !== 0 && (
                      <>
                        <StyledTableCell sx={styles.tableCell} />
                        <StyledTableCell sx={styles.tableCell} />
                        <StyledTableCell
                          sx={{ ...styles.tableCell, borderRight: 0 }}
                        />
                      </>
                    )}
                  </StyledTableRow>
                ))
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default ViewAllSteps
