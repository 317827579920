import React from 'react'

function GenerateWorklistFilesIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M14 8L18 4'
        stroke='#121314'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M19 7V3H15'
        stroke='#121314'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19 11V17C19 19.2091 17.2091 21 15 21H8C5.79086 21 4 19.2091 4 17V8.65685C4 7.59599 4.42143 6.57857 5.17157 5.82843L6.82843 4.17157C7.57857 3.42143 8.59599 3 9.65685 3H11'
        stroke='#121314'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 12H15'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 16H15'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default GenerateWorklistFilesIcon
