import React from 'react'

function DeleteIcon() {
  return (
    <div className='flex items-start gap-2.5 rounded-lg'>
      <svg
        width={24}
        height={24}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7.49933 16.4243L6.15301 7H17.847L16.5007 16.4243C16.2895 17.9022 15.0238 19 13.5308 19H10.4692C8.97623 19 7.71047 17.9022 7.49933 16.4243Z'
          stroke='black'
          strokeWidth={2}
        />
        <path
          d='M4 7H20'
          stroke='black'
          strokeWidth={2}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10 4H14'
          stroke='black'
          strokeWidth={2}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  )
}

export default DeleteIcon
