import React from 'react'

function AddReagentIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.0'
      width={24}
      height={24}
      viewBox='0 0 570.000000 428.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,428.000000) scale(0.100000,-0.100000)'
        fill='#000000'
        stroke='none'
      >
        <path d='M3387 3895 c-25 -17 -70 -77 -136 -178 -212 -326 -261 -385 -380 -461 -39 -25 -74 -46 -77 -46 -4 0 -44 23 -89 50 -88 53 -152 80 -191 80 -40 0 -86 -53 -155 -176 -101 -180 -107 -214 -46 -271 18 -17 112 -76 210 -133 120 -69 176 -107 173 -116 -3 -8 -130 -231 -282 -496 -528 -919 -468 -805 -499 -952 -31 -147 -29 -183 14 -236 l30 -38 -55 -98 c-118 -214 -126 -310 -35 -407 37 -41 73 -57 126 -57 85 0 177 71 191 147 10 53 -10 177 -46 285 -17 50 -27 94 -22 97 4 3 60 39 123 79 63 39 124 80 136 90 21 16 74 106 552 937 118 204 219 376 225 383 10 10 35 -2 126 -56 261 -154 304 -172 362 -153 19 6 46 24 60 39 28 31 98 148 133 225 55 116 23 167 -172 272 -28 15 -54 33 -56 39 -7 17 10 199 23 248 7 24 66 155 133 293 108 222 122 257 125 312 4 53 1 69 -22 107 -29 49 -76 82 -236 162 -122 61 -186 69 -243 29z m-450 -1380 c23 -14 43 -28 43 -31 0 -2 -33 -62 -74 -132 -40 -70 -207 -359 -371 -642 -250 -432 -306 -521 -348 -560 -56 -51 -76 -64 -84 -57 -8 9 26 146 46 186 49 98 695 1213 723 1249 14 16 15 16 65 -13z' />
      </g>
    </svg>
  )
}

export default AddReagentIcon
