import React from 'react'
import Button from '../../components/ui/Button'
import { useForm } from 'react-hook-form'
import { loginRequest } from '../../api/auth.api'
import { useNavigate } from 'react-router-dom'
import { InputForm } from '../../components/ui/InputForm'
import FormHeader from '../../components/ui/FormHeader'
import { UserIcon, PasswordIcon } from '../../components/ui/icons'
import { toast } from 'sonner'
import { useAuth } from '../../context/AuthContext'

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()
  const navigate = useNavigate()
  const { fetchUserProfile } = useAuth()

  const onSubmit = async data => {
    try {
      const res = await loginRequest(data)
      if (res.data.access_token) {
        window.localStorage.setItem('token', res.data.access_token)
        await fetchUserProfile()
        navigate('/experiments')
      }
    } catch (error) {
      console.error(error)
      if (error.message === 'Network Error') {
        toast.error('Network error. Please try again later.')
        return
      }
      if (error.response.data === 'Invalid email or password') {
        toast.error('Invalid email or password')
      } else toast.error('An error occurred. Please try again later.')
    }
  }

  const email = watch('email') // Get the value of the email field
  const password = watch('password') // Get the value of the password field

  return (
    <div className='flex justify-center items-center h-screen w-screen m-0'>
      <div className='flex w-[27.375rem] p-7 flex-col items-center gap-10 rounded-lg'>
        <FormHeader title='Welcome back!' description={'Sign in'} />
        <form
          className='flex flex-col items-start gap-10 self-stretch'
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputForm
            type='email'
            label={'Email'}
            placeholder={'Email'}
            {...register('email', { required: 'Email Address is required' })}
            aria-invalid={errors.email ? 'true' : 'false'}
            IconComponent={UserIcon}
          />
          {errors.email && (
            <div className='text-red-500'>{errors.email.message}</div>
          )}
          <InputForm
            type='password'
            label={'Password'}
            placeholder={'Password'}
            {...register('password', {
              required: 'Password is required',
            })}
            IconComponent={PasswordIcon}
          />

          <div className="text-zinc-700 text-xs font-bold font-['Nunito Sans'] leading-none">
            <a href='/forgot-password' className='hover:text-blue-500'>
              Forgot your password?
            </a>
          </div>

          <Button color='black' type='submit' disabled={!email || !password}>
            Sign in
          </Button>
          <div className='w-full flex justify-center items-center text-zinc-700 text-xs font-bold font-["Nunito Sans"]'>
            <span>Don't have an account? </span>
            <a href='/register' className='text-gray-500 underline ml-1'>
             Create account
            </a>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LoginForm
