import React from 'react'
import { Button } from '@mui/material'

function EditMastermixButton({ handleEditMastermix}) {
  return <Button
    variant="contained"
    color="primary"
    onClick={() => handleEditMastermix()}
  >Create Mastermix</Button>
}

export default EditMastermixButton
