import { forwardRef, useState } from 'react'
import ViewIcon from './icons/ViewIcon'

export const InputForm = forwardRef(
  ({ type, label, IconComponent, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false)

    return (
      <div className='flex flex-col w-full'>
        {/* <div className="text-neutral-400 text-xs font-semibold font-['Nunito Sans'] leading-none mb-1 ml-3">
          {label}
        </div> */}
        <div className='w-full h-11 px-3 py-2.5 bg-zinc-100 rounded-lg flex items-center gap-3'>
          <div className='w-6 h-6 flex justify-center items-center'>
            {IconComponent && <IconComponent />}
          </div>

          <input
            type={
              type === 'password' ? (showPassword ? 'text' : 'password') : type
            }
            className="w-full bg-transparent text-left text-zinc-950 text-sm font-semibold font-['Nunito Sans'] leading-tight border-none focus:ring-0 placeholder-neutral-400 focus:outline-none"
            ref={ref}
            {...props}
          />

          {/* append eye icon if type is password */}
          {type === 'password' && (
            <div
              className='w-6 h-6 flex justify-center items-center'
              onClick={() => {
                setShowPassword(!showPassword)
              }}
            >
              <ViewIcon stroke='black' />
            </div>
          )}
        </div>
      </div>
    )
  }
)
