import axios from './axios'

export const getDocumentsRequest = experimentId => {
  return axios.get(`/documents/experiment/${experimentId}`)
}

export const getDocumentRequest = documentId => {
  return axios.get(`/documents/${documentId}`)
}

export const uploadDocumentRequest = (experimentId, file) => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post(`/documents/${experimentId}/upload`, formData)
}

export const deleteDocumentRequest = documentId => {
  return axios.delete(`/documents/${documentId}`)
}
