import React, { useEffect, useState, useRef } from 'react'
import EditIcon from './icons/EditIcon'
import CopyIcon from './icons/CopyIcon'
import DeleteIcon from './icons/DeleteIcon'
import { getExperimentRequest } from '../../api/experiments.api'
import {
  createMastermixRequest,
  deleteMastermixRequest,
  updateMastermixRequest,
  updateRecipesRequest,
} from '../../api/mastermix.api'
import DialogModal from './DialogModal'
import AddReagentIcon from './icons/AddReagentIcon'
import {
  dispenseTypeOptions,
  liquidTypeOptions,
  tipWashingOptions,
  concentrationUnitOptions as units,
} from '../../constants'

const headers = [
  { label: 'Mastermix', key: 'mastermix', columnSize: 'w-[18%]' },
  { label: 'Source', key: 'finalSource', columnSize: 'w-[10%]' },
  { label: 'Unit', key: 'unit', columnSize: 'w-[8%]' },
  {
    label: 'Final Concentration',
    key: 'finalConcentration',
    columnSize: 'w-[9%]',
  },
  {
    label: 'Stock Concentration',
    key: 'stockConcentration',
    columnSize: 'w-[9%]',
  },
  { label: 'Tip Washing', key: 'tipWashing', columnSize: 'w-[7%]' },
  { label: 'Liquid Type', key: 'liquidType', columnSize: 'w-[10%]' },
  { label: 'Dispense Type', key: 'dispenseType', columnSize: 'w-[10%]' },
  { label: 'Actions', key: 'actions', columnSize: 'w-[12%]' },
  { label: 'Remove Reagent', key: 'removeReagent', columnSize: 'w-[7%]' },
]

const thClassName = 'px-5 py-2 text-center text-xs font-bold text-[#1e1e1e]'
const tdClassName =
  'px-2 py-2 text-[#1e1e1e] text-sm text-center border-r border-gray-200'

const MastermixTable = ({ experimentId, newMastermixesTrigger }) => {
  const [mastermixes, setMastermixes] = useState([])
  const [editableMixId, setEditableMixId] = useState(null)
  const [editedMastermixes, setEditedMastermixes] = useState({})
  const editInputRef = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [mixToDelete, setMixToDelete] = useState(null)
  const [hoveredRow, setHoveredRow] = useState({
    mixId: null,
    reagentIdx: null,
  })
  const [reagentToRemove, setReagentToRemove] = useState({
    mixId: null,
    reagentIdx: null,
  })
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  // 'edit' for edition of mastermix and 'add' for adding a reagent
  const [editMastermixOrAddReagent, setEditMastermixOrAddReagent] =
    useState(null)

  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')

  useEffect(() => {
    const fetchMastermixes = async () => {
      try {
        const response = await getExperimentRequest(experimentId)
        setMastermixes(
          response.data.masterMixes.sort((a, b) => a.orderIndex - b.orderIndex)
        )

        const initialEditState = response.data.masterMixes.reduce(
          (acc, mix) => {
            acc[mix.id] = {
              nameOfMasterMix: mix.nameOfMasterMix,
              recipes: JSON.parse(JSON.stringify(mix.recipes)),
            }
            return acc
          },
          {}
        )

        setEditedMastermixes(initialEditState)
      } catch (error) {
        console.error('Error fetching mastermixes:', error)
      }
    }

    fetchMastermixes()
  }, [experimentId, newMastermixesTrigger])

  useEffect(() => {
    if (editableMixId && editInputRef.current) {
      editInputRef.current.focus()
    }
  }, [editableMixId])

  const handleEditChange = (mixId, reagentIdx, field, value) => {
    setEditMastermixOrAddReagent('edit')
    if (value.length > 50) {
      setErrorMessage('Input length must be less than 50 characters.')
      setIsErrorModalOpen(true)
      return
    }

    setEditedMastermixes(prev => {
      const updated = { ...prev }
      if (field === 'nameOfMasterMix') {
        updated[mixId].nameOfMasterMix = value
      } else {
        updated[mixId].recipes[reagentIdx] = {
          ...updated[mixId].recipes[reagentIdx],
          [field]: value,
        }
      }
      return updated
    })
  }

  const handleSaveEdit = async id => {
    const mastermix = editedMastermixes[id]
    const reagents = mastermix.recipes

    for (let reagent of reagents) {
      const { stockConcentration, finalConcentration } = reagent
      if (parseFloat(stockConcentration) <= parseFloat(finalConcentration)) {
        setErrorMessage(
          'Stock concentration must be greater than final concentration for all reagents.'
        )
        setIsErrorModalOpen(true)
        return
      }
      if (
        parseFloat(stockConcentration) <= 0 ||
        parseFloat(finalConcentration) <= 0
      ) {
        setErrorMessage(
          'Stock concentration and final concentration must be greater than 0.'
        )
        setIsErrorModalOpen(true)
        return
      }
    }

    try {
      await updateMastermixRequest(id, {
        nameOfMasterMix: mastermix.nameOfMasterMix,
      })
      await updateRecipesRequest(id, { recipes: reagents })
      // update state here
      setMastermixes(prev =>
        prev.map(mix =>
          mix.id === id
            ? {
                ...mix,
                nameOfMasterMix: mastermix.nameOfMasterMix,
                recipes: JSON.parse(JSON.stringify(reagents)),
              }
            : mix
        )
      )
      setEditedMastermixes(prev => {
        const updated = { ...prev }
        updated[id] = { ...mastermix }
        return updated
      })
    } catch (error) {
      console.error('Error updating mastermix:', error)
    } finally {
      setEditableMixId(null)
    }
  }

  const handleCancelEdit = mixId => {
    if (editMastermixOrAddReagent === 'edit') {
      setEditedMastermixes(prev => {
        const original = {
          ...mastermixes.find(mix => mix.id === mixId),
          recipes: JSON.parse(
            JSON.stringify(mastermixes.find(mix => mix.id === mixId).recipes)
          ),
        }

        return {
          ...prev,
          [mixId]: {
            nameOfMasterMix: original.nameOfMasterMix,
            recipes: [...original.recipes],
          },
        }
      })
    } else if (editMastermixOrAddReagent === 'add') {
      setEditedMastermixes(prev => {
        const updatedMix = { ...prev[mixId] }
        updatedMix.recipes.pop()
        return {
          ...prev,
          [mixId]: updatedMix,
        }
      })

      // also pop in the mastermixes state
      setMastermixes(prev =>
        prev.map(mix =>
          mix.id === mixId
            ? {
                ...mix,
                recipes: mix.recipes.slice(0, mix.recipes.length - 1),
              }
            : mix
        )
      )
    }

    setEditableMixId(null)
    setEditMastermixOrAddReagent(null)
  }

  const handleDeleteMastermix = async id => {
    try {
      await deleteMastermixRequest(id)
      const response = await getExperimentRequest(experimentId)
      setMastermixes(response.data.masterMixes)
      setIsModalOpen(false)
    } catch (error) {
      console.error('Error deleting mastermix:', error)
    }
  }

  const onCancel = () => {
    setIsModalOpen(false)
  }

  const onContinue = () => {
    handleDeleteMastermix(mixToDelete)
    setIsModalOpen(false)
  }

  const handleSetMastermixToDelete = id => {
    setMixToDelete(id)
    setTitle('Remove mastermix')
    setMessage(
      'You are about to remove a mastermix with data. Do you want to continue?'
    )
    setIsModalOpen(true)
  }

  const handleCopyMastermix = async id => {
    try {
      const mmixFound = mastermixes.find(mix => mix.id === id)
      const payload = {
        nameOfMasterMix: mmixFound.nameOfMasterMix,
      }
      const copyResponse = await createMastermixRequest(experimentId, payload)
      const recipesPayload = mmixFound.recipes.map(recipe => {
        return {
          finalSource: recipe.finalSource,
          finalConcentration: recipe.finalConcentration,
          tipWashing: recipe.tipWashing,
          stockConcentration: recipe.stockConcentration,
          liquidType: recipe.liquidType,
          dispenseType: recipe.dispenseType,
          unit: recipe.unit,
        }
      })
      const updatedRecipesResponse = await updateRecipesRequest(
        copyResponse.data.id,
        {
          recipes: recipesPayload,
        }
      )
      const updatedRecipes = updatedRecipesResponse.data
      setMastermixes(prev => [
        ...prev,
        {
          id: copyResponse.data.id,
          nameOfMasterMix: copyResponse.data.name_of_mastermix,
          recipes: updatedRecipes,
        },
      ])
      setEditedMastermixes(prev => {
        const updated = { ...prev }
        updated[copyResponse.data.id] = {
          nameOfMasterMix: copyResponse.data.name_of_mastermix,
          recipes: updatedRecipes,
        }
        return updated
      })
    } catch (error) {
      console.error('Error copying mastermix:', error)
    }
  }

  const handleAddReagent = async mixId => {
    // Ensure any previous edit or add operation is cancelled before adding a new reagent
    // if (editMastermixOrAddReagent) {
    //   handleCancelEdit(mixId)
    // }
    // Set the state to add mode explicitly
    // setEditMastermixOrAddReagent('add')
    setEditedMastermixes(prev => {
      const updated = { ...prev }
      const mix = updated[mixId] || { recipes: [] } // Ensure the mix is initialized if not already

      mix.recipes.push({
        finalSource: '',
        unit: '',
        finalConcentration: 0,
        tipWashing: '',
        stockConcentration: 0,
        liquidType: '',
        dispenseType: '',
      })
      updated[mixId] = mix

      setMastermixes(prev =>
        prev.map(mix =>
          mix.id === mixId
            ? {
                ...mix,
                recipes: [
                  ...(mix.recipes || []),
                  mix.recipes[mix.recipes.length - 1],
                ],
              }
            : mix
        )
      )

      return updated
    })

    // Set the mastermix ID to be edited

    setEditableMixId(mixId)
  }

  const handleRemoveReagent = (mixId, reagentIdx) => {
    // Store the mastermix ID and reagent index in state
    setTitle('Remove reagent')
    setMessage(
      'You are about to remove a reagent from the mastermix. Do you want to continue?'
    )
    setReagentToRemove({ mixId, reagentIdx })
    // Open the confirmation modal
    setIsModalOpen(true)
  }

  const onContinueRemovingReagent = async () => {
    setIsModalOpen(false)

    const { mixId, reagentIdx } = reagentToRemove

    try {
      await updateReagentList(mixId, reagentIdx)

      setEditedMastermixes(prev => {
        const updated = { ...prev }
        updated[mixId].recipes = JSON.parse(
          JSON.stringify(
            updated[mixId].recipes.filter((_, idx) => idx !== reagentIdx)
          )
        )
        return updated
      })
    } catch (error) {
      console.error('Error removing reagent:', error)
    }
  }

  const updateReagentList = async (mixId, reagentIdx) => {
    const mixToUpdate = mastermixes.find(mix => mix.id === mixId)
    const updatedRecipes = mixToUpdate.recipes.filter(
      (_, idx) => idx !== reagentIdx
    )

    await updateRecipesRequest(mixId, { recipes: updatedRecipes })

    setMastermixes(prev =>
      prev.map(mix =>
        mix.id === mixId ? { ...mix, recipes: updatedRecipes } : mix
      )
    )
  }

  return (
    <>
      <div className='inline-flex flex-col items-start pb-5 mt-3 mb-8 rounded-xl border bg-white'>
        <table className='min-w-full divide-y divide-gray-200 table-fixed w-full'>
          <thead className='bg-[#eaeaeb]'>
            <tr>
              {headers.map(header => (
                <th
                  key={header.key}
                  className={`${thClassName} ${header.columnSize}`}
                >
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='bg-white divide-y divide-gray-200'>
            {mastermixes.map((mix, mixIdx) => {
              const mixRowSpan = mix.recipes?.length
              return mix.recipes?.map((reagent, reagentIdx) => (
                <tr
                  key={`reagent-${mixIdx}-${reagentIdx}`}
                  id={`reagent-${mixIdx}-${reagentIdx}`}
                  className={mix.id === hoveredRow ? 'bg-gray-200' : ''}
                  onMouseEnter={() =>
                    setHoveredRow({ mixId: mix.id, reagentIdx })
                  }
                  onMouseLeave={() =>
                    setHoveredRow({ mixId: null, reagentIdx: null })
                  }
                >
                  {headers.map((header, idx) => {
                    const isEditable = editableMixId === mix.id
                    const isLastReagent = reagentIdx === mix.recipes.length - 1
                    const lastRowBorderBottom = isLastReagent
                      ? 'border-b border-b-black'
                      : 'border-b'
                    const value =
                      header.key === 'mastermix' && reagentIdx === 0
                        ? editedMastermixes[mix.id]?.nameOfMasterMix
                        : editedMastermixes[mix.id]?.recipes[reagentIdx]?.[
                            header.key
                          ] || ''

                    if (header.key === 'mastermix' && reagentIdx === 0) {
                      return (
                        <td
                          key={idx}
                          className={`${tdClassName} border-b border-b-black ${
                            hoveredRow.mixId === mix.id ? 'bg-slate-200' : ''
                          } border-b border-black border-l`}
                          rowSpan={mixRowSpan}
                        >
                          {isEditable ? (
                            <input
                              type='text'
                              className='text-center text-sm text-gray-500 w-full'
                              value={value}
                              onChange={e =>
                                handleEditChange(
                                  mix.id,
                                  reagentIdx,
                                  'nameOfMasterMix',
                                  e.target.value
                                )
                              }
                              ref={reagentIdx === 0 ? editInputRef : null}
                            />
                          ) : (
                            mix.nameOfMasterMix
                          )}
                        </td>
                      )
                    } else if (header.key === 'finalSource') {
                      return (
                        <td
                          key={idx}
                          className={`${tdClassName} ${lastRowBorderBottom} ${
                            hoveredRow.mixId === mix.id &&
                            hoveredRow.reagentIdx === reagentIdx
                              ? 'bg-slate-200'
                              : ''
                          }`}
                        >
                          {isEditable ? (
                            <input
                              type='text'
                              value={value}
                              onChange={e =>
                                handleEditChange(
                                  mix.id,
                                  reagentIdx,
                                  header.key,
                                  e.target.value
                                )
                              }
                              className='w-full text-center text-sm text-gray-500'
                            />
                          ) : (
                            reagent[header.key]
                          )}
                        </td>
                      )
                    } else if (header.key === 'unit') {
                      return (
                        <td
                          key={idx}
                          className={`${tdClassName} ${lastRowBorderBottom} ${
                            hoveredRow.mixId === mix.id &&
                            hoveredRow.reagentIdx === reagentIdx
                              ? 'bg-slate-200'
                              : ''
                          }`}
                        >
                          {isEditable ? (
                            // Render a dropdown for the 'unit' field
                            <select
                              value={value}
                              onChange={e =>
                                handleEditChange(
                                  mix.id,
                                  reagentIdx,
                                  header.key,
                                  e.target.value
                                )
                              }
                              className='w-full text-center text-sm text-gray-500'
                            >
                              {units.map(unit => (
                                <option key={unit} value={unit}>
                                  {unit}
                                </option>
                              ))}
                            </select>
                          ) : (
                            reagent[header.key]
                          )}
                        </td>
                      )
                    } else if (header.key === 'finalConcentration') {
                      return (
                        <td
                          key={idx}
                          className={`${tdClassName} ${lastRowBorderBottom} ${
                            hoveredRow.mixId === mix.id &&
                            hoveredRow.reagentIdx === reagentIdx
                              ? 'bg-slate-200'
                              : ''
                          }`}
                        >
                          {isEditable ? (
                            <input
                              type='number'
                              value={value}
                              onChange={e =>
                                handleEditChange(
                                  mix.id,
                                  reagentIdx,
                                  header.key,
                                  e.target.value
                                )
                              }
                              className='w-full text-center text-sm text-gray-500'
                            />
                          ) : (
                            reagent[header.key]
                          )}
                        </td>
                      )
                    } else if (header.key === 'stockConcentration') {
                      return (
                        <td
                          key={idx}
                          className={`${tdClassName} ${lastRowBorderBottom} ${
                            hoveredRow.mixId === mix.id &&
                            hoveredRow.reagentIdx === reagentIdx
                              ? 'bg-slate-200'
                              : ''
                          }`}
                        >
                          {isEditable ? (
                            <input
                              type='number'
                              value={value}
                              onChange={e =>
                                handleEditChange(
                                  mix.id,
                                  reagentIdx,
                                  header.key,
                                  e.target.value
                                )
                              }
                              className='w-full text-center text-sm text-gray-500'
                            />
                          ) : (
                            reagent[header.key]
                          )}
                        </td>
                      )
                    } else if (header.key === 'tipWashing') {
                      return (
                        <td
                          key={idx}
                          className={`${tdClassName} ${lastRowBorderBottom} ${
                            hoveredRow.mixId === mix.id &&
                            hoveredRow.reagentIdx === reagentIdx
                              ? 'bg-slate-200'
                              : ''
                          }`}
                        >
                          {isEditable ? (
                            <select
                              value={value}
                              onChange={e =>
                                handleEditChange(
                                  mix.id,
                                  reagentIdx,
                                  header.key,
                                  e.target.value
                                )
                              }
                              className='w-full text-center text-sm text-gray-500'
                            >
                              {tipWashingOptions.map(liquidType => (
                                <option key={liquidType} value={liquidType}>
                                  {liquidType}
                                </option>
                              ))}
                            </select>
                          ) : (
                            reagent[header.key]
                          )}
                        </td>
                      )
                    } else if (header.key === 'liquidType') {
                      return (
                        <td
                          key={idx}
                          className={`${tdClassName} ${lastRowBorderBottom} ${
                            hoveredRow.mixId === mix.id &&
                            hoveredRow.reagentIdx === reagentIdx
                              ? 'bg-slate-200'
                              : ''
                          }`}
                        >
                          {isEditable ? (
                            <select
                              value={value}
                              onChange={e =>
                                handleEditChange(
                                  mix.id,
                                  reagentIdx,
                                  header.key,
                                  e.target.value
                                )
                              }
                              className='w-full text-center text-sm text-gray-500'
                            >
                              {liquidTypeOptions.map(liquidType => (
                                <option key={liquidType} value={liquidType}>
                                  {liquidType}
                                </option>
                              ))}
                            </select>
                          ) : (
                            reagent[header.key]
                          )}
                        </td>
                      )
                    } else if (header.key === 'dispenseType') {
                      return (
                        <td
                          key={idx}
                          className={`${tdClassName} ${lastRowBorderBottom} ${
                            hoveredRow.mixId === mix.id &&
                            hoveredRow.reagentIdx === reagentIdx
                              ? 'bg-slate-200'
                              : ''
                          }`}
                        >
                          {isEditable ? (
                            <select
                              value={value}
                              onChange={e =>
                                handleEditChange(
                                  mix.id,
                                  reagentIdx,
                                  header.key,
                                  e.target.value
                                )
                              }
                              className='w-full text-center text-sm text-gray-500'
                            >
                              {dispenseTypeOptions.map(dispenseType => (
                                <option key={dispenseType} value={dispenseType}>
                                  {dispenseType}
                                </option>
                              ))}
                            </select>
                          ) : (
                            reagent[header.key]
                          )}
                        </td>
                      )
                    } else if (header.key === 'actions' && reagentIdx === 0) {
                      return (
                        <td
                          key={`actions-${mixIdx}`}
                          rowSpan={mixRowSpan}
                          className={`${tdClassName} border-b border-b-black ${
                            hoveredRow.mixId === mix.id ? 'bg-slate-200' : ''
                          } border-b border-black border-l`}
                        >
                          <div
                            className={`flex items-center justify-center space-x-2 w-full`}
                          >
                            {editableMixId === mix.id ? (
                              <>
                                <button
                                  className='text-white bg-black font-medium rounded-lg text-sm text-center px-1'
                                  onClick={() => handleSaveEdit(mix.id)}
                                >
                                  Save
                                </button>
                                <button
                                  className='text-gray-900 bg-white border border-gray-300 font-medium rounded-lg text-sm text-center px-1'
                                  onClick={() => handleCancelEdit(mix.id)}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  onClick={() => {
                                    setEditableMixId(mix.id)
                                    setEditMastermixOrAddReagent('edit')
                                  }}
                                  data-tooltip-content={'Edit Mastermix'}
                                  data-tooltip-id='my-tooltip'
                                >
                                  <EditIcon />
                                </button>
                                <button
                                  onClick={() => handleCopyMastermix(mix.id)}
                                  data-tooltip-content={'Clone Mastermix'}
                                  data-tooltip-id='my-tooltip'
                                >
                                  <CopyIcon />
                                </button>
                                <button
                                  onClick={() =>
                                    handleSetMastermixToDelete(mix.id)
                                  }
                                  data-tooltip-content={'Delete Mastermix'}
                                  data-tooltip-id='my-tooltip'
                                >
                                  <DeleteIcon />
                                </button>
                                <button
                                  onClick={() => {
                                    setEditMastermixOrAddReagent('add')
                                    handleAddReagent(mix.id)
                                  }}
                                  data-tooltip-content={'Add Reagent'}
                                  data-tooltip-id='my-tooltip'
                                >
                                  <AddReagentIcon />
                                </button>
                              </>
                            )}
                          </div>
                        </td>
                      )
                    } else if (header.key === 'removeReagent') {
                      return (
                        <td
                          key={idx}
                          className={`${tdClassName} ${lastRowBorderBottom} ${
                            hoveredRow.mixId === mix.id &&
                            hoveredRow.reagentIdx === reagentIdx
                              ? 'bg-slate-200'
                              : ''
                          }`}
                        >
                          <button
                            className='w-full text-center justify-center text-sm text-gray-500'
                            onClick={() =>
                              handleRemoveReagent(mix.id, reagentIdx)
                            }
                          >
                            <div className='flex justify-center'>
                              <DeleteIcon />
                            </div>
                          </button>
                        </td>
                      )
                    } else return null
                  })}
                </tr>
              ))
            })}
          </tbody>
        </table>
      </div>
      <DialogModal
        isOpen={isModalOpen}
        onCancel={onCancel}
        onContinue={() => {
          if (title === 'Remove mastermix') {
            onContinue()
          } else if (title === 'Remove reagent') {
            onContinueRemovingReagent()
          }
        }}
        title={title}
        message={message}
      />

      {
        <DialogModal
          isOpen={isErrorModalOpen}
          onCancel={() => setIsErrorModalOpen(false)}
          title='Error'
          message={errorMessage}
          onContinue={() => setIsErrorModalOpen(false)}
        />
      }
    </>
  )
}

export default MastermixTable
