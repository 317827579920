import React, { useEffect, useRef, useState } from 'react'
import EditIcon from './icons/EditIcon'
import { updateExperimentRequest } from '../../api/experiments.api'
import DialogModal from './DialogModal'
import ViewIcon from './icons/ViewIcon'
import CopyIcon from './icons/CopyIcon'
import UploadFileIcon from './icons/UploadFileIcon'
import GenerateWorklistFilesIcon from './icons/GenerateWorklistFilesIcon'
import { Tooltip } from 'react-tooltip'

const ExperimentPlanEdit = ({
  experimentalPlanData,
  experimentId,
  setExperimentDetails,
  actions: {
    handleView,
    handleCopy,
    handleUploadFile,
    handleGenerateWorklistFiles,
  },
}) => {
  const [isExpEdit, setIsExptEdit] = useState(false)
  const firstInputRef = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [modalTitle, setModalTitle] = useState('')

  const [nameOfExperimentalPlan, setNameOfExperimentalPlan] = useState(
    experimentalPlanData.nameOfExperimentalPlan
  )
  const [numOfSampleConcentrations, setNumOfSampleConcentrations] = useState(
    experimentalPlanData.numOfSampleConcentrations
  )
  const [numOfTechnicalReplicates, setNumOfTechnicalReplicates] = useState(
    experimentalPlanData.numOfTechnicalReplicates
  )
  const [mastermixVolumePerReaction, setMastermixVolumePerReaction] = useState(
    experimentalPlanData.mastermixVolumePerReaction
  )
  const [sampleVolumePerReaction, setSampleVolumePerReaction] = useState(
    experimentalPlanData.sampleVolumePerReaction
  )
  const [pcrPlateSize, setPcrPlateSize] = useState(
    experimentalPlanData.pcrPlateSize
  )

  const handleEdit = () => {
    setIsExptEdit(true)
    setTimeout(() => {
      const input = firstInputRef.current
      if (input) {
        input.focus()
        input.select()
      }
    }, 0)
  }

  const handleModalCancel = () => {
    setIsModalOpen(false) // Simply close the modal
  }

  const handleUpdateExperiment = async () => {
    if (nameOfExperimentalPlan.length > 50) {
      setModalMessage(
        'The name of the experimental plan cannot exceed 50 characters. Please adjust your values'
      )
      setModalTitle('Character Limit Exceeded')
      setIsModalOpen(true)
      return
    }

    if (
      parseInt(mastermixVolumePerReaction) + parseInt(sampleVolumePerReaction) >
      150
    ) {
      setModalMessage(
        'The sum of mastermix volume per reaction and sample volume per reaction cannot exceed 150. Please adjust your values'
      )
      setModalTitle('Volume Limit Exceeded')
      setIsModalOpen(true)
      return
    }

    if (
      mastermixVolumePerReaction < 1 ||
      mastermixVolumePerReaction > 200 ||
      mastermixVolumePerReaction % 1 !== 0
    ) {
      setModalMessage(
        'The mastermix volume per reaction must be between 1 and 50 and must be a whole number. Please adjust your values'
      )
      setModalTitle('Invalid Mastermix Volume per Reaction')
      setIsModalOpen(true)
      return
    }

    if (
      numOfSampleConcentrations < 1 ||
      numOfSampleConcentrations > 100 ||
      numOfSampleConcentrations % 1 !== 0
    ) {
      setModalMessage(
        'The number of samples must be between 1 and 100 and must be a whole number. Please adjust your values'
      )
      setModalTitle('Invalid Number of Samples')
      setIsModalOpen(true)
      return
    }

    if (
      numOfTechnicalReplicates < 1 ||
      numOfTechnicalReplicates > 50 ||
      numOfTechnicalReplicates % 1 !== 0
    ) {
      setModalMessage(
        'The number of technical replicates must be between 1 and 50 and must be a whole number. Please adjust your values'
      )
      setModalTitle('Invalid Number of Technical Replicates')
      setIsModalOpen(true)
      return
    }

    // validate sample volume per reaction, min = 1, max = 50
    if (
      sampleVolumePerReaction < 1 ||
      sampleVolumePerReaction > 50 ||
      sampleVolumePerReaction % 1 !== 0
    ) {
      setModalMessage(
        'The sample volume per reaction must be between 1 and 50 and must be a whole number. Please adjust your values'
      )
      setModalTitle('Invalid Sample Volume per Reaction')
      setIsModalOpen(true)
      return
    }

    // Proceed with updating the experiment as the condition is not met
    const response = await updateExperimentRequest(experimentId, {
      nameOfExperimentalPlan,
      numOfSampleConcentrations,
      numOfTechnicalReplicates,
      mastermixVolumePerReaction,
      sampleVolumePerReaction,
      pcrPlateSize,
    })

    if (response && response.data) {
      setExperimentDetails(response.data)
    }
    setIsExptEdit(false)
  }

  const handleCancel = () => {
    setNameOfExperimentalPlan(experimentalPlanData.nameOfExperimentalPlan)
    setNumOfSampleConcentrations(experimentalPlanData.numOfSampleConcentrations)
    setNumOfTechnicalReplicates(experimentalPlanData.numOfTechnicalReplicates)
    setMastermixVolumePerReaction(
      experimentalPlanData.mastermixVolumePerReaction
    )
    setSampleVolumePerReaction(experimentalPlanData.sampleVolumePerReaction)
    setPcrPlateSize(experimentalPlanData.pcrPlateSize)

    setIsExptEdit(!isExpEdit)
    setTimeout(() => {
      firstInputRef.current?.focus()
    }, 0)
  }

  useEffect(() => {
    setNameOfExperimentalPlan(experimentalPlanData.nameOfExperimentalPlan || '')
    setNumOfSampleConcentrations(
      experimentalPlanData.numOfSampleConcentrations || ''
    )
    setNumOfTechnicalReplicates(
      experimentalPlanData.numOfTechnicalReplicates || ''
    )
    setMastermixVolumePerReaction(
      experimentalPlanData.mastermixVolumePerReaction || ''
    )
    setSampleVolumePerReaction(
      experimentalPlanData.sampleVolumePerReaction || ''
    )
    setPcrPlateSize(experimentalPlanData.pcrPlateSize || '')
  }, [experimentalPlanData])

  return (
    <div className='inline-flex flex-col items-start pb-5 mt-3 rounded-xl border bg-white'>
      <table className='min-w-full'>
        <thead className='bg-[#eaeaeb]'>
          <tr>
            <th className='px-4 py-3 border border-[#d9d9da] font-bold text-xs text-[#121314]'>
              Experiment plan
            </th>
            <th className='px-4 py-3 border border-[#d9d9da] font-bold text-xs text-[#121314]'>
              Number of samples
            </th>
            <th className='px-4 py-3 border border-[#d9d9da] font-bold text-xs text-[#121314]'>
              Number of technical replicates
            </th>
            <th className='px-4 py-3 border border-[#d9d9da] font-bold text-xs text-[#121314]'>
              Mastermix volume per reaction (uL)
            </th>
            <th className='px-4 py-3 border border-[#d9d9da] font-bold text-xs text-[#121314]'>
              Sample volume per reaction (uL)
            </th>
            <th className='px-4 py-3 border border-[#d9d9da] font-bold text-xs text-[#121314]'>
              PCR Plate size
            </th>
            <th className='px-4 py-3 border border-[#d9d9da] font-bold text-xs text-[#121314]'>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className='border-b border-[#d9d9da] text-center'>
            <td className='border-r p-3 border-[#d9d9da] text-xs text-[#09090a]'>
              {isExpEdit ? (
                <input
                  ref={firstInputRef}
                  type='text'
                  onChange={e => setNameOfExperimentalPlan(e.target.value)}
                  value={nameOfExperimentalPlan}
                  className='border-[#d9d9da] text-xs text-center text-[#09090a] focus:outline-none focus:ring-2 focus:ring-coolGray'
                />
              ) : (
                nameOfExperimentalPlan
              )}
            </td>
            <td className='border-r border-[#d9d9da] text-xs text-[#09090a]'>
              {isExpEdit ? (
                <input
                  type='number'
                  onChange={e => setNumOfSampleConcentrations(e.target.value)}
                  value={numOfSampleConcentrations}
                  className='border-[#d9d9da] text-xs text-center text-[#09090a] focus:outline-none focus:ring-2 focus:ring-coolGray'
                />
              ) : (
                numOfSampleConcentrations
              )}
            </td>
            <td className='border-r border-[#d9d9da] text-xs text-[#09090a]'>
              {isExpEdit ? (
                <input
                  type='number'
                  onChange={e => setNumOfTechnicalReplicates(e.target.value)}
                  value={numOfTechnicalReplicates}
                  className='border-[#d9d9da] text-xs text-center text-[#09090a] focus:outline-none focus:ring-2 focus:ring-coolGray'
                />
              ) : (
                numOfTechnicalReplicates
              )}
            </td>
            <td className='border-r border-[#d9d9da] text-xs text-[#09090a]'>
              {isExpEdit ? (
                <input
                  type='number'
                  onChange={e => setMastermixVolumePerReaction(e.target.value)}
                  value={mastermixVolumePerReaction}
                  className='border-[#d9d9da] text-xs text-center text-[#09090a] focus:outline-none focus:ring-2 focus:ring-coolGray'
                />
              ) : (
                mastermixVolumePerReaction
              )}
            </td>
            <td className='border-r border-[#d9d9da] text-xs text-[#09090a]'>
              {isExpEdit ? (
                <input
                  type='number'
                  onChange={e => setSampleVolumePerReaction(e.target.value)}
                  value={sampleVolumePerReaction}
                  className='border-[#d9d9da] text-xs text-center text-[#09090a] focus:outline-none focus:ring-2 focus:ring-coolGray'
                />
              ) : (
                sampleVolumePerReaction
              )}
            </td>
            <td className='border-r border-[#d9d9da] text-xs text-[#09090a]'>
              {isExpEdit ? (
                <select
                  onChange={e => setPcrPlateSize(e.target.value)}
                  value={pcrPlateSize}
                  className='border-[#d9d9da] text-xs text-center text-[#09090a] focus:outline-none focus:ring-2 focus:ring-coolGray'
                >
                  <option value='96'>96</option>
                  <option value='384'>384</option>
                </select>
              ) : (
                pcrPlateSize
              )}
            </td>
            <td className='text-xs text-[#09090a]'>
              <div className='flex items-center justify-center gap-4'>
                {isExpEdit ? (
                  <>
                    <button
                      className='text-white bg-black font-medium rounded-lg text-sm text-center px-1'
                      onClick={handleUpdateExperiment}
                    >
                      Save
                    </button>
                    <button
                      className='text-gray-900 bg-white border border-gray-300 font-medium rounded-lg text-sm text-center px-1'
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <div className='flex gap-2 p-2'>
                    <button
                      data-tooltip-content='Edit Experiment'
                      data-tooltip-id='my-tooltip'
                      onClick={handleEdit}
                    >
                      <EditIcon />
                    </button>
                    <button
                      data-tooltip-content='Instruction Viewer'
                      data-tooltip-id='my-tooltip'
                      onClick={() => handleView(experimentId)}
                    >
                      <ViewIcon stroke={'#121314'} />
                    </button>
                    <button
                      data-tooltip-content='Clone Experiment'
                      data-tooltip-id='my-tooltip'
                      onClick={() => handleCopy(experimentId)}
                    >
                      <CopyIcon />
                    </button>
                    <button
                      data-tooltip-content='Upload Documents'
                      data-tooltip-id='my-tooltip'
                      onClick={() => handleUploadFile(experimentId)}
                    >
                      <UploadFileIcon />
                    </button>
                    <button
                      data-tooltip-content='Generate Worklist Files'
                      data-tooltip-id='my-tooltip'
                      onClick={() => handleGenerateWorklistFiles(experimentId)}
                    >
                      <GenerateWorklistFilesIcon />
                    </button>
                    <Tooltip
                      id='my-tooltip'
                      place='top'
                      type='dark'
                      effect='solid'
                    />
                  </div>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <DialogModal
        isOpen={isModalOpen}
        onCancel={handleModalCancel}
        onContinue={handleModalCancel}
        title={modalTitle}
        message={modalMessage}
      />
    </div>
  )
}

export default ExperimentPlanEdit
