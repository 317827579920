import React, { useEffect } from 'react'
import CSVFileIcon from './icons/CSVFileIcon'
import TXTFileIcon from './icons/TXTFileIcon'
import WorklistViewerIcon from './icons/WorklistViewerIcon'
import DownloadIcon from './icons/DownloadIcon'
import ViewIcon from './icons/ViewIcon'
import { getMastermixWorklistData } from '../GenerateMastermixWorklist'
import { getSampleWorklistData } from '../GenerateSampleWorklist'
import { CSVLink } from 'react-csv'
import { COLUMN_HEADERS } from '../../config/worklist/TableHeaderRow'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LOCAL_KEY_STORAGE } from '../../config/Configurations'
import { getInstructionData } from '../GenerateInstructionData'

const FILE_NAME = {
  EXPERIMENTAL_PLAN: 'experimental-plan-RoboNAAT.txt',
  MASTERMIX_WORKLIST: 'mastermix_worklist.csv',
  SAMPLE_WORKLIST: 'sample_worklist.csv',
}

const SPECIAL_CHAR_FILE = '*****-----*****'

function GenerateWorklistFilesComponent({
  experiment,
  mastermixes,
  experimentIdProp,
}) {
  const [mastermixData, setMastermixData] = useState([])
  const [sampleData, setSampleData] = useState([])
  const navigate = useNavigate()
  const { experimentId } = useParams()

  console.log("mastermixes: ", mastermixes)
  console.log("experiment: ", experiment);

  useEffect(() => {
    const mastermixWorklistData = getMastermixWorklistData(
      mastermixes,
      experiment,
      true
    )
    setMastermixData(mastermixWorklistData)

    const sampleWorklistData = getSampleWorklistData(
      mastermixes,
      experiment,
      true
    )
    setSampleData(sampleWorklistData)
  }, [experiment, mastermixes])

  const exportTableTextFile = () => {
    const fileData =
      'DO NOT EDIT THIS FILE\n\n\n' +
      SPECIAL_CHAR_FILE +
      '\n\n\n' +
      JSON.stringify(experiment, null, '\t') +
      '\n\n\n' +
      SPECIAL_CHAR_FILE +
      '\n\n\n' +
      JSON.stringify(mastermixes, null, '\t')
    const element = document.createElement('a')
    const file = new Blob([fileData], { type: 'text/plain;charset=utf-8' })
    element.href = URL.createObjectURL(file)
    element.download = FILE_NAME.EXPERIMENTAL_PLAN
    document.body.appendChild(element)
    element.click()
  }

  const saveLocalStorageData = selectedExperiment => {
    const commonExperimentalPlan = {
      experimentalPlanID: selectedExperiment.id,
      nameOfExperimentalPlan: selectedExperiment.nameOfExperimentalPlan,
      masterMixVolumnPerReaction: selectedExperiment.masterMixVolumnPerReaction,
      sampleVolumnPerReaction: selectedExperiment.sampleVolumnPerReaction,
      numOfSampleConcentrations: selectedExperiment.numOfSampleConcentrations,
      numOfTechnicalReplicates: selectedExperiment.numOfTechnicalReplicates,
      pcrPlateSize: selectedExperiment.pcrPlateSize,
    }

    localStorage.setItem(
      LOCAL_KEY_STORAGE.COMMON_EXPERIMENTAL_PLAN_FIELDS,
      JSON.stringify([commonExperimentalPlan])
    )

    const allMasterMixesWithRecipes = selectedExperiment.masterMixes

    localStorage.setItem(
      LOCAL_KEY_STORAGE.RECIPE_STEPS,
      JSON.stringify(allMasterMixesWithRecipes)
    )

    const instructionData = getInstructionData(allMasterMixesWithRecipes, [
      commonExperimentalPlan,
    ])
    localStorage.setItem(
      LOCAL_KEY_STORAGE.EXPERIMENTAL_PLAN,
      JSON.stringify(instructionData)
    )
  }
 

  const handleView = () => {
    experiment[0].masterMixes = mastermixes
    saveLocalStorageData(experiment[0])
    navigate(`/experiments/${experimentIdProp}/instructions`)
  }

  return (
    <div
      className={`flex flex-col items-start mx-20 mt-7 w-[85vw] ${
        !experimentId ? 'overflow-y-scroll h-[28rem]' : ''
      }`}
    >
      <div className='Sans text-[#434447] font-nunito text-4xl font-bold leading-[52px]'>
        Generate worklist files for experiment:{' '}
        {experiment[0].nameOfExperimentalPlan}
      </div>
      <div className='flex justify-between mt-5 items-start gap-7 self-stretch p-5 rounded-xl border border-[#d9d9da] bg-white'>
        <div className='flex items-start gap-4'>
          <CSVFileIcon />
          <div className='flex flex-col items-start gap-2'>
            <div className='flex flex-col items-start gap-1 self-stretch'>
              <div className='Sans text-[#434447] font-nunito text-xl font-bold leading-7'>
                Worklist files
              </div>
              <div className='Sans self-stretch text-[#434447] font-nunito leading-5'>
                The .csv worklist file guides the robot' actions and their
                sequence in Hamilton run control.
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-between gap-3 whitespace-nowrap'>
          <CSVLink
            data={mastermixData}
            headers={COLUMN_HEADERS}
            filename={FILE_NAME.MASTERMIX_WORKLIST}
            target='_blank'
          >
            <button className='flex justify-center items-center gap-2 self-stretch py-2 pl-5 pr-4 rounded-lg bg-[#121314] w-52'>
              <div className='Sans text-white text-center font-nunito font-bold leading-6'>
                Mastermix worklist
              </div>
              <DownloadIcon stroke='white' />
            </button>
          </CSVLink>
          <CSVLink
            data={sampleData}
            headers={COLUMN_HEADERS}
            filename={FILE_NAME.SAMPLE_WORKLIST}
            target='_blank'
          >
            <button className='flex justify-center items-center gap-2 self-stretch py-2 pl-5 pr-4 rounded-lg border border-[#121314] w-52'>
              <div className='Sans text-[#121314] text-center font-nunito font-bold leading-6'>
                Sample worklist
              </div>
              <DownloadIcon stroke='#121314' />
            </button>
          </CSVLink>
        </div>
      </div>
      <div className='Sans text-[#434447] font-nunito text-[1.75rem] font-bold leading-[52px]'>
        Actions
      </div>
      <div className='flex justify-between items-start gap-7 self-stretch p-5 rounded-xl border border-[#d9d9da] bg-white'>
        <div className='flex items-start gap-4'>
          <TXTFileIcon />
          <div className='flex flex-col items-start gap-2'>
            <div className='flex flex-col items-start gap-1 self-stretch'>
              <div className='Sans text-[#434447] font-nunito text-xl font-bold leading-7'>
                Export and save experimental plan data
              </div>
              <div className='Sans self-stretch text-[#434447] font-nunito leading-5'>
                The file contains data of experimental plan entered by user. The
                file is used to import existing worklist and experimental plan.
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-center items-center self-stretch  w-52'>
          <button
            onClick={exportTableTextFile}
            className='flex justify-center items-center gap-2 self-stretch py-2 pl-5 pr-4 rounded-lg bg-[#121314] w-52'
          >
            <div className='Sans text-white text-center font-nunito font-bold leading-6'>
              Export
            </div>
            <DownloadIcon stroke='white' />
          </button>
        </div>
      </div>
      <div className='flex justify-between items-start gap-7 self-stretch p-5 mt-7 rounded-xl border border-[#d9d9da] bg-white'>
        <div className='flex items-start gap-4'>
          <WorklistViewerIcon />
          <div className='flex flex-col items-start gap-2'>
            <div className='flex flex-col items-start gap-1 self-stretch'>
              <div className='Sans text-[#434447] font-nunito text-xl font-bold leading-7'>
                RoboNAAT Worklist viewer
              </div>
              <div className='Sans self-stretch text-[#434447] font-nunito leading-5'>
                The web app interactively guides the loading of reagents for an
                automatically generated experiment on a RoboNAAT capable robot.
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-center items-center self-stretch w-52'>
          <button
            onClick={handleView}
            className='flex justify-center items-center gap-2 self-stretch py-2 pl-5 pr-4 rounded-lg bg-[#121314] w-52'
          >
            <div className='Sans text-white text-center font-nunito font-bold leading-6'>
              View
            </div>
            <ViewIcon stroke='white' />
          </button>
        </div>
      </div>
    </div>
  )
}

export default GenerateWorklistFilesComponent
