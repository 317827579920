import React from 'react'

function UploadFileIcon({color = '#121314'}) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 9H10V4'
        stroke={color}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 21L12 15'
        stroke={color}
        strokeWidth={2}
        strokeLinecap='round'
      />
      <path
        d='M9 16L12 13L15 16'
        stroke={color}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 21C9 20.4477 8.55228 20 8 20C6.89543 20 6 19.1046 6 18V9.65685C6 9.12642 6.21071 8.61771 6.58579 8.24264L10.2426 4.58579C10.6177 4.21071 11.1264 4 11.6569 4H16C17.1046 4 18 4.89543 18 6V18C18 19.1046 17.1046 20 16 20C15.4477 20 15 20.4477 15 21C15 21.5523 15.4477 22 16 22C18.2091 22 20 20.2091 20 18V6C20 3.79086 18.2091 2 16 2H11.6569C10.596 2 9.57857 2.42143 8.82843 3.17157L5.17157 6.82843C4.42143 7.57857 4 8.59599 4 9.65685V18C4 20.2091 5.79086 22 8 22C8.55228 22 9 21.5523 9 21Z'
        fill={color}
      />
    </svg>
  )
}

export default UploadFileIcon
