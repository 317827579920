import React from 'react'

const EmailSentIcon = () => {
  return (
    <svg
      width='120'
      height='120'
      viewBox='0 0 120 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M36 55.5L19.5 55.5'
        stroke='#6D6D70'
        strokeLinecap='round'
        stroke-dasharray='2 2'
      />
      <rect
        x='36'
        y='33'
        width='78'
        height='54'
        rx='2'
        fill='url(#paint0_linear_1_4353)'
      />
      <path
        d='M68.1043 75.7782L36 37.5V36H114V37.5L81.8957 75.7782C78.2988 80.0668 71.7012 80.0668 68.1043 75.7782Z'
        fill='#989899'
      />
      <path
        d='M69.3431 70.8431L36 37.5V35C36 33.8954 36.8954 33 38 33H112C113.105 33 114 33.8954 114 35V37.5L80.6569 70.8431C77.5327 73.9673 72.4673 73.9673 69.3431 70.8431Z'
        fill='#F2F2F3'
      />
      <path
        d='M45 73.5L12 73.5'
        stroke='#6D6D70'
        strokeLinecap='round'
        stroke-dasharray='2 2'
      />
      <path
        d='M54 64.5L3 64.5'
        stroke='#6D6D70'
        strokeLinecap='round'
        stroke-dasharray='2 2'
      />
      <ellipse
        cx='57'
        cy='2.25'
        rx='57'
        ry='2.25'
        transform='matrix(1 0 0 -1 0 102)'
        fill='url(#paint1_linear_1_4353)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1_4353'
          x1='99.75'
          y1='38.25'
          x2='36'
          y2='92.25'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#E2E2E2' />
          <stop offset='1' stop-color='#ADAFB5' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1_4353'
          x1='112.5'
          y1='1.49999'
          x2='-13.5'
          y2='1.49999'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#C3C1C1' />
          <stop offset='1' stop-color='#D9D9D9' stop-opacity='0' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default EmailSentIcon
