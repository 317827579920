import React from 'react'

function Button({ color = 'black', children, disabled, ...props }) {
  let buttonClass =
    "text-center text-lg font-bold font-['Nunito Sans'] leading-normal w-full"

  if (disabled) {
    buttonClass += ' bg-[#d9d9da] text-white'
  } else {
    buttonClass +=
      color === 'black'
        ? ' bg-neutral-900 text-white'
        : ' border border-zinc-600 text-zinc-600'
  }

  return (
    <button
      className={`w-full px-4 py-2 rounded-lg ${buttonClass}`}
      style={{ lineHeight: '2.5rem' }}
      {...props}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
