import { useState, useEffect } from 'react'

function Dropdown({ options, value, onChange }) {
  const [modifiedOptions, setModifiedOptions] = useState([
    { label: 'Select', value: '', disabled: true, hidden: true },
  ])

  useEffect(() => {
    const newOptions = [
      { label: 'Select', value: '', disabled: true, hidden: true, color: 'text-red-200' },
      ...options.map(option => ({ label: option, value: option })),
    ]
    setModifiedOptions(newOptions)
  }, [options])

  return (
    <select
      onChange={onChange}
      value={value}
      className={
      `flex items-center gap-3 py-1 w-full rounded-lg bg-[#f2f2f3] text-[#09090a] text-center font-nunito text-sm font-semibold leading-5 ${
        value === '' ? 'text-[#9e9ea7]' : 'text-[#09090a]'
      }`
      }
      placeholder='Select'
    >
      {modifiedOptions.map((option, index) => (
        <option
          key={index}
          value={option.value}
          disabled={option.disabled}
          hidden={option.hidden}
        >
          {option.label}
        </option>
      ))}
    </select>
  )
}

export default Dropdown
