import React, { useState, useEffect } from 'react'
import Button from '../ui/Button'
import NewExperimentalPlanMenu from './NewExperimentalPlanMenu'

const WelcomeBar = ({ name, areExperiments }) => {
  const [isMenuVisible, setMenuVisible] = useState(false)

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible)
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (isMenuVisible && !event.target.closest('.menu-container')) {
        setMenuVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [isMenuVisible])

  return (
    <div className='flex justify-between items-center w-full'>
      <div className='flex flex-col justify-center gap-4 w-full'>
        <div className='text-[#505050] font-nunito text-2xl font-bold leading-5'>
          Welcome, {name}!
        </div>
        {areExperiments && (
          <div className='text-[#121314] font-nunito leading-6 text-nowrap'>
            These are your recent experiments
          </div>
        )}
      </div>
      <div className='menu-container w-full flex justify-end items-center'>
        <div
          className='scale-75'
          style={{ transform: 'scale(0.75)', transformOrigin: '100% 50%' }}
        >
          <Button onClick={toggleMenu}>
            New experimental plan
            <span className='inline-flex items-center ml-2'>
              <svg
                width={20}
                height={20}
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9 11L12.2426 14.2426L15.5 11'
                  stroke='white'
                  strokeWidth={2}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </span>
          </Button>
        </div>
        {isMenuVisible && <NewExperimentalPlanMenu />}
      </div>
    </div>
  )
}

export default WelcomeBar
