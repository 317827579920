import React from 'react'

function DownloadIcon({ stroke }) {
  return (
    <div className='flex-shrink-0'>
      <svg
        width={24}
        height={24}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M5 17L6.12132 18.1213C6.68393 18.6839 7.44699 19 8.24264 19H15.7574C16.553 19 17.3161 18.6839 17.8787 18.1213L19 17'
          stroke={stroke}
          strokeWidth={2}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12 5.46448V13.4645'
          stroke={stroke}
          strokeWidth={2}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.46973 11.4645L12.0053 15L15.5408 11.4645'
          stroke={stroke}
          strokeWidth={2}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  )
}

export default DownloadIcon
