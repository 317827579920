import React from 'react'
import ReactModal from 'react-modal'
import WarningIcon from './icons/WarningIcon'

const DialogModal = ({
  isOpen,
  onCancel,
  onContinue,
  title,
  message,
  ...props
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => onCancel()}
      overlayClassName='fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50'
      className='inline-flex flex-col items-start rounded-xl border border-[#d9d9da] bg-white'
      {...props}
    >
      <div className='flex flex-col items-start gap-3 pt-6 pb-4 px-6'>
        <div className='flex items-center gap-3'>
          <div className='w-8 h-8'>
            <WarningIcon />
          </div>
          <div className='text-black font-nunito text-2xl font-bold leading-9'>
            {title}
          </div>
        </div>
        <div className='text-black font-nunito leading-6'>{message}</div>
      </div>
      <div className='flex justify-end items-center gap-3 pt-4 pb-6 px-6 w-[541px]'>
        <button
          onClick={onCancel}
          className='flex items-center gap-2 py-2 px-5 rounded-lg border border-[#58595c] text-[#58595c] text-center font-nunito font-bold leading-6'
        >
          Cancel
        </button>
        <button
          onClick={onContinue}
          className='flex items-center gap-2 py-2 px-5 rounded-lg bg-[#121314] text-white text-center font-nunito font-bold leading-6'
        >
          Continue
        </button>
      </div>
    </ReactModal>
  )
}

export default DialogModal
