import React from 'react'

function ResetIcon() {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.6571 6.34315C16.4118 5.09788 14.7904 4.29831 13.0444 4.06844C11.2984 3.83857 9.52537 4.19126 8.00023 5.0718C6.4751 5.95233 5.28313 7.31151 4.6092 8.93853C3.93526 10.5656 3.81703 12.3695 4.27283 14.0706'
        stroke='#58595C'
        strokeWidth={2}
        strokeLinecap='round'
      />
      <path
        d='M6.34291 17.6569C7.58818 18.9021 9.20955 19.7017 10.9556 19.9316C12.7016 20.1614 14.4746 19.8087 15.9998 18.9282C17.5249 18.0477 18.7169 16.6885 19.3908 15.0615C20.0647 13.4344 20.183 11.6305 19.7272 9.92945'
        stroke='#58595C'
        strokeWidth={2}
        strokeLinecap='round'
      />
      <path
        d='M18 4V7H15'
        stroke='#58595C'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 20L6 17L9 17'
        stroke='#58595C'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ResetIcon
