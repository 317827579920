import React from 'react'

function RightArrowIcon() {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='white'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.75 15.25L16.9926 12.0074L13.75 8.75'
        stroke='white'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7 12H16.5'
        stroke='white'
        strokeWidth={2}
        strokeLinecap='round'
      />
    </svg>
  )
}

export default RightArrowIcon
