import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
  deleteDocumentRequest,
  getDocumentRequest,
  getDocumentsRequest,
  uploadDocumentRequest,
} from '../../api/documents.api'
import { formatFileSize, localeDate } from '../../utils/utils'
import UploadFileIcon from './icons/UploadFileIcon'
import { toast } from 'sonner'
import { DNA } from 'react-loader-spinner'
import DownloadIcon from './icons/DownloadIcon'
import DeleteIcon from './icons/DeleteIcon'
import DialogModal from './DialogModal'

function ExperimentDocuments() {
  const [documents, setDocuments] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { experimentId } = useParams()
  const fileInputRef = React.createRef()
  const { experimentName } = useLocation().state
  const [isOpen, setIsOpen] = useState(false)
  const [documentToDeleteId, setDocumentToDeleteId] = useState(null)

  useEffect(() => {
    fetchDocuments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experimentId])

  const fetchDocuments = async () => {
    setLoading(true)
    try {
      const { data: documents } = await getDocumentsRequest(experimentId)
      setDocuments(documents)
    } catch (err) {
      console.error('Failed to fetch documents:', err)
      setError('Failed to fetch documents. Please try again later.')
    } finally {
      setLoading(false)
    }
  }

  const handleFileChange = async event => {
    const file = event.target.files[0]
    if (!file) return

    setLoading(true)
    setError('')
    try {
      await uploadDocumentRequest(experimentId, file)
      fetchDocuments()
    } catch (err) {
      console.error('Failed to upload document:', err)
      setError('Failed to upload document. Please try again.')
      // if file already exists, show error message
      if (err.response?.data?.message === 'File already exists') {
        setError('File already exists. Please upload a different file.')
        setTimeout(() => setError(''), 5000)
        toast.error('File already exists. Please upload a different file.')
      } else {
        setError('Failed to upload document. Please try again.')
        setTimeout(() => setError(''), 5000)
        toast.error('Failed to upload document. Please try again.')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleDownload = async (documentId, fileName) => {
    try {
      const response = await getDocumentRequest(documentId)

      const { presigned_url } = response.data

      console.log('presigned url: ', presigned_url)

      const link = document.createElement('a')
      link.href = presigned_url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error(
        'Error fetching presigned URL:',
        error.response ? error.response.data.message : error.message
      )
    }
  }

  const triggerFileInputClick = () => {
    fileInputRef.current.click()
  }

  const handleDeleteDocument = async documentId => {
    try {
      await deleteDocumentRequest(documentId)
      setDocuments(documents.filter(doc => doc.id !== documentId))
      toast.message('Document deleted successfully')
    } catch (error) {
      console.error('Failed to delete document:', error)
      setError('Failed to delete document. Please try again later.')
      toast.error('Failed to delete document. Please try again later.')
    }
  }

  const onCancel = () => {
    setIsOpen(false)
  }

  const onContinue = () => {
    setIsOpen(false)
    handleDeleteDocument(documentToDeleteId)
  }

  const handleSetDocumentToDelete = documentId => {
    setDocumentToDeleteId(documentId)
    setIsOpen(true)
  }

  return (
    <>
      <div className='mx-20'>
        <h1 className='Sans mt-20 text-[#505050] font-nunito text-2xl font-bold leading-5'>
          Experiment: {experimentName}
        </h1>

        <input
          type='file'
          ref={fileInputRef}
          onChange={handleFileChange}
          className='hidden'
        />

        <div className='flex mt-10'>
          <button
            className='flex justify-center items-center gap-2 self-stretch py-2 pl-5 pr-4 rounded-lg bg-[#121314] w-52 cursor-pointer'
            onClick={triggerFileInputClick}
            disabled={loading}
          >
            <div className='Sans text-white text-center font-nunito font-bold leading-6'>
              {loading ? 'Uploading...' : 'Upload Document'}
            </div>
            <UploadFileIcon color='white' />
          </button>
          {error && (
            <p style={{ color: 'red' }} className='ml-20'>
              {error}
            </p>
          )}
        </div>

        {loading && (
          <div className='flex items-center justify-center mt-40'>
            <div className='flex flex-col items-center justify-center bg-white p-2.5 rounded-lg grayscale'>
              <DNA height={100} width={100} />
              <div>Loading...</div>
            </div>
          </div>
        )}

        {!loading && documents.length > 0 ? (
          <div className='mt-6'>
            <table className='text-sm text-left w-full text-gray-500 border-r border-r-[#d9d9da] border-l border-l-[#d9d9da]'>
              <thead className='text-xs text-gray-700 bg-coolGray'>
                <tr>
                  <th className='py-3 px-6 border-r border-r-[#d9d9da]'>
                    File Name
                  </th>
                  <th className='py-3 px-6 border-r border-r-[#d9d9da]'>
                    File Size
                  </th>
                  <th className='py-3 px-6 border-r border-r-[#d9d9da]'>
                    Created Date
                  </th>
                  <th className='py-3 px-6'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {documents.map((doc, index) => (
                  <tr className='bg-white border-b' key={index}>
                    <td className='py-4 px-6 border-r border-r-[#d9d9da]'>
                      {doc.original_file_name}
                    </td>
                    <td className='py-4 px-6 border-r border-r-[#d9d9da]'>
                      {formatFileSize(doc.file_size)}
                    </td>
                    <td className='py-4 px-6 border-r border-r-[#d9d9da]'>
                      {localeDate(doc.created_at)}
                    </td>
                    <td className='py-4 px-6 flex justify-around'>
                      <button
                        onClick={() =>
                          handleDownload(doc.id, doc.original_file_name)
                        }
                      >
                        <DownloadIcon stroke={'black'} />
                      </button>
                      <button onClick={() => handleSetDocumentToDelete(doc.id)}>
                        <DeleteIcon />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          !loading && (
            <div className='mt-6 text-center'>
              <p>No documents available for this experiment.</p>
            </div>
          )
        )}
      </div>
      <DialogModal
        isOpen={isOpen}
        onCancel={onCancel}
        onContinue={onContinue}
        title={'Remove document'}
        message={'You are about to remove a document. Do you want to continue?'}
      />
    </>
  )
}

export default ExperimentDocuments
