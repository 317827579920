import React from 'react'

function LeftArrowIcon() {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.25 15.25L7.00736 12.0074L10.25 8.75'
        stroke='#58595C'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17 12H7.5'
        stroke='#58595C'
        strokeWidth={2}
        strokeLinecap='round'
      />
    </svg>
  )
}
export default LeftArrowIcon
