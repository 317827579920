import React from 'react'
import { useLocation } from 'react-router-dom'
import GenerateWorklistFilesComponent from '../components/ui/GenerateWorklistFilesComponent'

function GenerateWorklistFiles() {
  const location = useLocation()
  const state = location.state
  const experiment = [
    {
      nameOfExperimentalPlan: state.experimentDetails.nameOfExperimentalPlan,
      masterMixVolumnPerReaction:
        state.experimentDetails.mastermixVolumePerReaction,
      sampleVolumnPerReaction: state.experimentDetails.sampleVolumePerReaction,
      pcrPlateSize: state.experimentDetails.pcrPlateSize,
      numOfSampleConcentrations:
        state.experimentDetails.numOfSampleConcentrations,
      numOfTechnicalReplicates:
        state.experimentDetails.numOfTechnicalReplicates,
      experimentalPlanID: state.experimentDetails.id,
    },
  ]

  const mastermixes = state.experimentDetails.masterMixes.map(mastermix => {
    return {
      nameOfMasterMix: mastermix.nameOfMasterMix,
      recipeForEachMasterMix: mastermix.recipes,
      id: mastermix.id,
    }
  })

  return (
    <GenerateWorklistFilesComponent
      experiment={experiment}
      mastermixes={mastermixes}
    />
  )
}

export default GenerateWorklistFiles
