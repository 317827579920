import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { UserIcon } from './icons/UserIcon'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

const FilterOption = ({
  icon,
  text,
  show,
  type,
  onExperimentChange,
  onUserChange,
  handleDateFilterChange,
}) => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)

  const onDateChange = dates => {
    const [start, end] = dates.map(date => {
      if (date) {
        return new Date(date.setHours(0, 0, 0, 0))
      }
      return date
    })
    setStartDate(start)
    setEndDate(end)
  }

  useEffect(() => {
    if (handleDateFilterChange) {
      handleDateFilterChange({ startDate, endDate })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  if (show === undefined || show) {
    return (
      <div className='flex justify-between items-center py-2 px-3 w-[15.25rem] rounded-lg bg-[#f2f2f3]'>
        <div className='flex items-center gap-3'>
          {icon}
          {type === 'experiment' && (
            <input
              onChange={onExperimentChange}
              placeholder={text}
              className='text-[#121314] focus:outline-none bg-[#f2f2f3] text-center font-nunito text-sm font-semibold leading-5'
            />
          )}
          {type === 'user' && (
            <input
              onChange={onUserChange}
              placeholder={text}
              className='text-[#121314] focus:outline-none bg-[#f2f2f3] text-center font-nunito text-sm font-semibold leading-5'
            />
          )}
        </div>
        {text === 'Filter by date' && (
          <>
            <svg
              width={24}
              height={24}
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M9 11L12.2426 14.2426L15.5 11'
                stroke='#121314'
                strokeWidth={2}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <DatePicker
              selected={startDate}
              className='text-[#121314] text-center font-nunito text-sm font-semibold leading-3 bg-[#f2f2f3] border-none'
              onChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
            />
          </>
        )}
      </div>
    )
  }
  return null
}

function ExperimentsFilter({
  handleExperimentNameFilterChange,
  handleDateFilterChange,
  handleUserFilterChange,
}) {
  const { role } = useAuth()
  // add states for search, date, and user filters
  // add functions to handle changes in search, date, and user filters

  return (
    <div className='flex items-center gap-4 p-5 bg-white'>
      <div className='flex items-start gap-3'>
        <FilterOption
          show
          onExperimentChange={e =>
            handleExperimentNameFilterChange(e.target.value)
          }
          type={'experiment'}
          icon={
            <svg
              width={24}
              height={24}
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle
                cx='11.5'
                cy='11.5'
                r='6.5'
                stroke='#121314'
                strokeWidth={2}
              />
              <path
                d='M16.5 16.5L20 20'
                stroke='#121314'
                strokeWidth={2}
                strokeLinecap='round'
              />
            </svg>
          }
          text='Search by name'
        />
        <FilterOption
          show
          icon={
            <svg
              width={24}
              height={24}
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx={9} cy={8} r={2} stroke='#121314' strokeWidth={2} />
              <circle cx={15} cy={16} r={2} stroke='#121314' strokeWidth={2} />
              <path d='M4 7H20V9H4V7Z' fill='#121314' />
              <path d='M4 15H20V17H4V15Z' fill='#121314' />
            </svg>
          }
          text='Filter by date'
          handleDateFilterChange={handleDateFilterChange}
        />
        <FilterOption
          show={role === 'admin' || role === 'supervisor'}
          icon={<UserIcon />}
          text='Filter by user'
          type={'user'}
          onUserChange={e => handleUserFilterChange(e.target.value)}
        />
      </div>
    </div>
  )
}

export default ExperimentsFilter
