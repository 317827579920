import React from 'react';

const WarningIcon = () => (
  <div className="w-8 h-8">
    <svg width={40} height={42} viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_946_2714)">
        <path d="M17.1552 3.68411L6.41422 23.1357C5.20915 25.3181 6.77764 28 9.25904 28H30.741C33.2224 28 34.7909 25.3181 33.5858 23.1357L22.8448 3.68411C21.6049 1.43863 18.3951 1.43863 17.1552 3.68411Z" fill="white" />
        <path d="M7.28962 23.6191L18.0306 4.1675C18.8902 2.61083 21.1098 2.61083 21.9694 4.1675L32.7104 23.6191C33.5524 25.144 32.4507 27 30.741 27H9.25904C7.54931 27 6.44757 25.144 7.28962 23.6191Z" stroke="#989899" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <rect x="18" y="9" width="4" height="12" rx="2" fill="#434447" />
        <circle cx="20" cy="24" r="2" fill="#434447" />
      </g>
      <defs>
        <filter id="filter0_d_946_2714" x={0} y={0} width={40} height={38} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={3} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_946_2714" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_946_2714" result="shape" />
        </filter>
      </defs>
    </svg>
  </div>
);

export default WarningIcon;
