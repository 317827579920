import React from 'react'
import { Tooltip } from 'react-tooltip'

function InfoTooltipIcon({ tooltipMessage }) {
  return (
    <>
      <svg
        tabIndex='-1'
        data-tooltip-content={tooltipMessage}
        data-tooltip-id='my-tooltip'
        width={24}
        height={24}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx={12} cy={12} r={9} stroke='#58595C' strokeWidth={2} />
        <path
          d='M12 16L12 12'
          stroke='#58595C'
          strokeWidth={2}
          strokeLinecap='round'
        />
        <circle cx={12} cy={8} r={1} fill='#58595C' />
      </svg>
      <Tooltip
        id='my-tooltip'
        place='top'
        type='dark'
        effect='solid'
        className='max-w-[25%] tooltip-override'
      />
    </>
  )
}

export default InfoTooltipIcon
