export const PasswordIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3 14C3 12.3431 4.34315 11 6 11H18C19.6569 11 21 12.3431 21 14V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V14Z'
      stroke='#121314'
      strokeWidth='2'
    />
    <path
      d='M8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7V11H8V7Z'
      stroke='#121314'
      strokeWidth='2'
    />
    <path
      d='M12 15V17'
      stroke='#121314'
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
)
