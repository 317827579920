import React from 'react'

function ViewIcon({ stroke }) {
  return (
    <svg
      width={25}
      height={24}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.5 7C8.1 7 5.33333 10.3333 4.5 12C5.33333 13.6667 8.1 17 12.5 17C16.9 17 19.6667 13.6667 20.5 12C19.6667 10.3333 16.9 7 12.5 7Z'
        stroke={stroke}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5 14C13.6046 14 14.5 13.1046 14.5 12C14.5 11.795 14.4692 11.5972 14.4119 11.4111C14.2551 11.7583 13.9058 12 13.5 12C12.9477 12 12.5 11.5523 12.5 11C12.5 10.5942 12.7417 10.2449 13.0889 10.0881C12.9028 10.0308 12.705 10 12.5 10C11.3954 10 10.5 10.8954 10.5 12C10.5 13.1046 11.3954 14 12.5 14Z'
        fill={stroke}
      />
    </svg>
  )
}

export default ViewIcon
