import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { DNA } from 'react-loader-spinner'
import NavBar from '../../components/ui/NavBar'
import {
  getUsersRolesRequest,
  updateUserRoleRequest,
} from '../../api/users.api'
import { toast } from 'sonner'
import { localeDate } from '../../utils/utils'

function RolesSettings() {
  const { isLoading, name, role } = useAuth()
  const [usersRolesLoading, setUsersRolesLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [searchName, setSearchName] = useState('')
  const [searchEmail, setSearchEmail] = useState('')

  useEffect(() => {
    setUsersRolesLoading(true)
    const getUsersRoles = async () => {
      try {
        const response = await getUsersRolesRequest()
        setUsers(response.data)
      } catch (error) {
        console.error('Failed to fetch users roles:', error)
      }
      setUsersRolesLoading(false)
    }
    getUsersRoles()
  }, [])

  const filteredUsers = users.filter(
    user =>
      user.fullname.toLowerCase().includes(searchName.toLowerCase()) &&
      user.email.toLowerCase().includes(searchEmail.toLowerCase())
  )

  const handleInputChange = async (userId, newRole) => {
    const originalUsers = [...users]
    try {
      await updateUserRoleRequest(userId, newRole)
      const updatedUsers = users.map(user => {
        if (user.user_id === userId) {
          return { ...user, role: newRole }
        }
        return user
      })
      setUsers(updatedUsers)
      const user = updatedUsers.find(user => user.user_id === userId)
      toast.message(
        `Role of user ${user.fullname} has been updated to ${newRole}`
      )
    } catch (error) {
      console.error('Failed to update user role:', error)
      setTimeout(() => {
        setUsers(originalUsers)
      }, 3000)
      setUsers(originalUsers)
      if (error.response.data.errorIdentifier === 'CannotDowngradeRole') {
        toast.error(
          'Cannot downgrade role from admin to supervisor or user. At least one admin must be present.'
        )
      }
    }
  }

  if (isLoading || usersRolesLoading) {
    return (
      <div className='flex items-center justify-center h-screen'>
        <div className='flex flex-col items-center justify-center bg-white p-2.5 rounded-lg grayscale'>
          <DNA height={100} width={100} />
          <div>Loading...</div>
        </div>
      </div>
    )
  }

  return (
    <>
      <NavBar name={name} role={role} />
      <div className='Sans mt-20 ml-20 text-[#505050] font-nunito text-2xl font-bold leading-5'>
        Roles settings
      </div>
      <div className='ml-20 mt-4'>
        <div>
          A <strong>user</strong> role can access only their own experiments.
        </div>
        <div>
          A <strong>supervisor</strong> role can access their experiments and
          those of all users.
        </div>
        <div>
          An <strong>admin</strong> role, in addition to accessing the
          experiments of all users, can reassign roles.
        </div>
      </div>
      <div className='mt-6 mx-20'>
        <div>
          <div className='p-6 rounded-tl-xl rounded-tr-xl border-t border-t-[#d9d9da] border-r border-r-[#d9d9da] border-l border-l-[#d9d9da] '>
            <div className='absolute inset-y-0 w-11 left-0 flex items-center pl-3 pointer-events-none'>
              <svg
                className='w-5 h-5 text-gray-500'
                fill='white'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                  clipRule='evenodd'
                ></path>
              </svg>
            </div>
            <div className='flex gap-5'>
              <input
                type='text'
                className='bg-gray-50 border w-60 border-gray-300 text-gray-900 text-sm rounded-lg block pl-10 p-2.5 focus:outline-none'
                placeholder='Search by name'
                value={searchName}
                onChange={e => setSearchName(e.target.value)}
              />
              <input
                type='text'
                className='bg-gray-50 border w-60 border-gray-300 text-gray-900 text-sm rounded-lg block pl-10 p-2.5 focus:outline-none'
                placeholder='Search by email'
                value={searchEmail}
                onChange={e => setSearchEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
        <table className='text-sm w-full text-gray-500 border-r border-r-[#d9d9da] border-l border-l-[#d9d9da] '>
          <thead className='text-xs text-gray-700 bg-coolGray'>
            <tr>
              <th
                scope='col'
                className='py-3 px-6 border-r border-r-[#d9d9da] '
              >
                User name
              </th>
              <th scope='col' className='py-3 px-6 border-r border-r-[#d9d9da]'>
                Email
              </th>
              <th
                scope='col'
                className='py-3 px-6 border-r border-r-[#d9d9da] '
              >
                Last change update
              </th>
              <th
                scope='col'
                className='py-3 px-6 border-r border-r-[#d9d9da] '
              >
                User
              </th>
              <th
                scope='col'
                className='py-3 px-6 border-r border-r-[#d9d9da] '
              >
                Supervisor
              </th>
              <th scope='col' className='py-3 px-6'>
                Admin
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr className='bg-white border-b text-center' key={user.user_id}>
                <td className='py-4 px-6 border-r border-r-[#d9d9da] '>
                  {user.fullname}
                </td>
                <td className='py-4 px-6 border-r border-r-[#d9d9da] '>
                  {user.email}
                </td>
                <td className='py-4 px-6 border-r border-r-[#d9d9da] '>
                  {localeDate(user.role_updated_at)}
                </td>
                <td className='py-4 px-6 border-r border-r-[#d9d9da] '>
                  <input
                    type='radio'
                    name={`role${index}`}
                    id={`user${index}`}
                    value='User'
                    defaultChecked={user.role === 'user'}
                    checked={user.role === 'user'}
                    onChange={() => handleInputChange(user.user_id, 'user')}
                  />
                </td>
                <td className='py-4 px-6 border-r border-r-[#d9d9da] '>
                  <input
                    type='radio'
                    name={`role${index}`}
                    id={`supervisor${index}`}
                    value='Supervisor'
                    defaultChecked={user.role === 'supervisor'}
                    checked={user.role === 'supervisor'}
                    onChange={() =>
                      handleInputChange(user.user_id, 'supervisor')
                    }
                  />
                </td>
                <td className='py-4 px-6'>
                  <input
                    type='radio'
                    name={`role${index}`}
                    id={`admin${index}`}
                    value='Admin'
                    defaultChecked={user.role === 'admin'}
                    checked={user.role === 'admin'}
                    onChange={() => handleInputChange(user.user_id, 'admin')}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default RolesSettings
