import React from 'react'

function EditIcon() {
  return (
    <svg
      aria-hidden='true'
      width={25}
      height={24}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.00316 15.4222L6.41481 18.3639L9.35655 17.7756C9.74373 17.6982 10.0993 17.5078 10.3785 17.2286L19.1427 8.46444C19.9238 7.68339 19.9238 6.41706 19.1427 5.63602C18.3617 4.85497 17.0954 4.85497 16.3143 5.63602L7.5501 14.4002C7.2709 14.6794 7.08059 15.035 7.00316 15.4222Z'
        stroke='#121314'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.2529 7.40381L17.3742 9.52513'
        stroke='#121314'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default EditIcon
