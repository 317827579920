import React, { useEffect, useState } from 'react'
// eslint-disable-next-line no-unused-vars
import ExperimentsFilter from './ExperimentsFilter'
import ActionsContainer from './ActionsContainer'
import { localeDate } from '../../utils/utils'

const TABLE_HEADERS = [
  'Name of Experimental Plan',
  'Created Date and Time',
  'Updated Date and Time',
  'Experiment Owner',
  'Number of Samples',
  'Number of Technical Replicates',
  'Mastermix Volume per Reaction (uL)',
  'Sample Volume per Reaction (uL)',
  'PCR Plate Size',
  'Actions',
]

const getClassNameWidth = index => {
  let widthClass = ''
  switch (index) {
    case 0:
      widthClass = 'min-w-[5%] max-w-[15rem]'
      break
    case 1:
      widthClass = 'w-[20%] max-w-[12rem]'
      break
    case 2:
      widthClass = 'w-[20%] max-w-[3rem]'
      break
    case 3:
      widthClass = 'w-[5%] max-w-[6rem]'
      break
    case 4:
      widthClass = 'w-[5%] max-w-[6rem]'
      break
    case 5:
      widthClass = 'w-[5%] max-w-[6rem]'
      break
    case 6:
      widthClass = 'w-[5%] max-w-[6rem]'
      break
    case 7:
      widthClass = 'w-[5%] max-w-[6rem]'
      break
    case 8:
      widthClass = 'w-[15%]'
      break
    default:
      widthClass = 'w-[5%]'
  }
  return widthClass
}

function ExperimentsTable({
  experiments,
  actions: {
    handleEdit,
    handleView,
    handleCopy,
    handleUploadFile,
    handleGenerateWorklistFiles,
  },
}) {
  const [hoveredRow, setHoveredRow] = React.useState(-1)
  const [experimentsFiltered, setExperimentsFiltered] = useState([experiments])

  const handleExperimentNameFilterChange = value => {
    const filteredExperiments = experiments.filter(experiment =>
      experiment.nameOfExperimentalPlan
        .toLowerCase()
        .includes(value.toLowerCase())
    )
    setExperimentsFiltered(filteredExperiments)
  }

  useEffect(() => {
    setExperimentsFiltered(experiments)
  }, [experiments])

  const handleDateFilterChange = ({ startDate, endDate }) => {
    startDate?.setHours(0, 0, 0, 0)
    endDate?.setHours(23, 59, 59, 999)

    const filteredExperiments = experiments.filter(experiment => {
      const createdAt = new Date(experiment.createdAt)
      return createdAt >= startDate && createdAt <= endDate
    })

    setExperimentsFiltered(filteredExperiments)
  }

  const handleUserFilterChange = value => {
    const filteredExperiments = experiments.filter(experiment =>
      experiment.ownerFullName.toLowerCase().includes(value.toLowerCase())
    )
    setExperimentsFiltered(filteredExperiments)
  }

  const renderRowCells = (experiment, rowIndex) => {
    const {
      id,
      nameOfExperimentalPlan,
      mastermixVolumePerReaction,
      sampleVolumePerReaction,
      pcrPlateSize,
      numOfSampleConcentrations,
      numOfTechnicalReplicates,
      createdAt,
      updatedAt,
      ownerFullName,
    } = experiment

    const cellValues = [
      nameOfExperimentalPlan,
      localeDate(createdAt),
      localeDate(updatedAt),
      ownerFullName,
      numOfSampleConcentrations,
      numOfTechnicalReplicates,
      mastermixVolumePerReaction,
      sampleVolumePerReaction,
      pcrPlateSize,
      <ActionsContainer
        experimentId={id}
        handleEdit={() => handleEdit(id)}
        handleView={() => handleView(id)}
        handleCopy={() => handleCopy(id)}
        handleUploadFile={() => handleUploadFile(id)}
        handleGenerateWorklistFiles={() => handleGenerateWorklistFiles(id)}
        highlightBackground={hoveredRow === rowIndex}
      />,
    ]

    return cellValues.map((value, index) => {
      const widthClassName = getClassNameWidth(index)
      return (
        <td
          key={index}
          className={`px-3 py-2 text-sm text-[#1e1e1e] border-b border-gray-300 ${
            index < cellValues.length - 1 ? 'border-r' : ''
          } text-center ${widthClassName}`}
          onMouseEnter={() => setHoveredRow(rowIndex)}
          onMouseLeave={() => setHoveredRow(-1)}
        >
          {value}
        </td>
      )
    })
  }

  return (
    <div className='flex flex-col w-full overflow-x-auto rounded border-x border-t rounded-bl-xl rounded-br-xl border-r pb-5 border-r-[#d9d9da] border-b border-b-[#d9d9da] border-l border-l-[#d9d9da] mt-6 border-gray-300'>
      <ExperimentsFilter
        handleExperimentNameFilterChange={handleExperimentNameFilterChange}
        handleDateFilterChange={handleDateFilterChange}
        handleUserFilterChange={handleUserFilterChange}
      />
      <table>
        <thead className='bg-coolGray'>
          <tr>
            {TABLE_HEADERS.map((header, index) => {
              const widthClass = getClassNameWidth(index)
              return (
                <th
                  key={index}
                  scope='col'
                  className={`px-3 py-1 text-left text-xs font-medium text-[#121314] tracking-wider border-t border-b border-gray-300 ${
                    index < TABLE_HEADERS.length - 1 ? 'border-r' : ''
                  } text-center ${widthClass}`}
                >
                  {header}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody className='bg-white divide-y divide-gray-200'>
          {experimentsFiltered
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((experiment, rowIndex) => (
              <tr
                key={experiment.id || rowIndex}
                className='hover:bg-slate-200'
              >
                {renderRowCells(experiment, rowIndex)}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default ExperimentsTable
