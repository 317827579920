import React from 'react'

function ImportIcon() {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 17L6.12132 18.1213C6.68393 18.6839 7.44699 19 8.24264 19H15.7574C16.553 19 17.3161 18.6839 17.8787 18.1213L19 17'
        stroke='black'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 15V7'
        stroke='black'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.47021 9L12.0057 5.46447L15.5413 9'
        stroke='black'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ImportIcon
