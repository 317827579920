import React from 'react'

const CopyIcon = () => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x={9}
      y={9}
      width={10}
      height={10}
      rx={3}
      stroke='#121314'
      strokeWidth={2}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 6H12C13.1046 6 14 6.89543 14 8H16C16 5.79086 14.2091 4 12 4H8C5.79086 4 4 5.79086 4 8V12C4 14.2091 5.79086 16 8 16V14C6.89543 14 6 13.1046 6 12V8C6 6.89543 6.89543 6 8 6Z'
      fill='#121314'
    />
  </svg>
)

export default CopyIcon
