import React from 'react'

function CreateNewIcon() {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx={12} cy={12} r={9} stroke='#121314' strokeWidth={2} />
      <path
        d='M12 8V16'
        stroke='#121314'
        strokeWidth={2}
        strokeLinecap='round'
      />
      <path d='M8 12H16' stroke='black' strokeWidth={2} strokeLinecap='round' />
    </svg>
  )
}

export default CreateNewIcon
