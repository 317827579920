import React from 'react'

function WorklistViewerIcon() {
  return (
    <div className='w-20 h-20'>
      <svg
        width='80'
        height='80'
        viewBox='0 0 80 80'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17 73H60C61.1046 73 62 72.1046 62 71V9C62 7.89543 61.1046 7 60 7H31.1846C30.6428 7 30.1241 7.21984 29.7473 7.60923L15.5627 22.2681C15.2018 22.6411 15 23.1398 15 23.6589V71C15 72.1046 15.8954 73 17 73Z'
          fill='url(#paint0_linear_1_4332)'
        />
        <path
          d='M30 24H15V22.8284C15 22.298 15.2107 21.7893 15.5858 21.4142L29.4142 7.58579C29.7893 7.21071 30.298 7 30.8284 7H32V22C32 23.1046 31.1046 24 30 24Z'
          fill='#F2F2F3'
        />
        <g filter='url(#filter0_d_1_4332)'>
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M50 24C49.4477 24 49 24.4477 49 25V37.0883C49 37.9731 48.4119 38.7394 47.5958 39.0812C42.5469 41.1957 39 46.1836 39 52C39 59.732 45.268 66 53 66C60.732 66 67 59.732 67 52C67 46.1836 63.4531 41.1957 58.4042 39.0812C57.5881 38.7394 57 37.9731 57 37.0883V25C57 24.4477 56.5523 24 56 24H50Z'
            fill='url(#paint1_linear_1_4332)'
            shape-rendering='crispEdges'
          />
        </g>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M30 33.2929L31.2286 34.5215C31.4238 34.7167 31.4238 35.0333 31.2286 35.2286C31.0333 35.4238 30.7167 35.4238 30.5214 35.2286L30 34.7071L29.3536 35.3536C29.1583 35.5488 28.8417 35.5488 28.6464 35.3536C28.4512 35.1583 28.4512 34.8417 28.6464 34.6465L30 33.2929ZM32.2714 36.2715C32.4667 36.0762 32.7833 36.0762 32.9786 36.2715L34.7286 38.0215C34.9238 38.2167 34.9238 38.5333 34.7286 38.7286C34.5333 38.9238 34.2167 38.9238 34.0214 38.7286L32.2714 36.9786C32.0762 36.7833 32.0762 36.4667 32.2714 36.2715ZM27.3536 36.6465C27.5488 36.8417 27.5488 37.1583 27.3536 37.3536L26.2071 38.5H24.875C24.5989 38.5 24.375 38.2762 24.375 38C24.375 37.7239 24.5989 37.5 24.875 37.5H25.7929L26.6464 36.6465C26.8417 36.4512 27.1583 36.4512 27.3536 36.6465ZM21 38C21 37.7239 21.2239 37.5 21.5 37.5H22.625C22.9011 37.5 23.125 37.7239 23.125 38C23.125 38.2762 22.9011 38.5 22.625 38.5H21.5C21.2239 38.5 21 38.2762 21 38ZM39.6464 37.6465C39.8417 37.4512 40.1583 37.4512 40.3536 37.6465C40.5488 37.8417 40.5488 38.1583 40.3536 38.3536L39.6036 39.1036C39.4083 39.2988 39.0917 39.2988 38.8964 39.1036C38.7012 38.9083 38.7012 38.5917 38.8964 38.3965L39.6464 37.6465ZM35.7714 39.7715C35.9667 39.5762 36.2833 39.5762 36.4786 39.7715L37 40.2929L37.3964 39.8965C37.5917 39.7012 37.9083 39.7012 38.1036 39.8965C38.2988 40.0917 38.2988 40.4083 38.1036 40.6036L37 41.7071L35.7714 40.4786C35.5762 40.2833 35.5762 39.9667 35.7714 39.7715Z'
          fill='#6D6D70'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M31 43.2929L32.3536 44.6465C32.5488 44.8417 32.5488 45.1583 32.3536 45.3536C32.1583 45.5488 31.8417 45.5488 31.6464 45.3536L31 44.7071L30.6036 45.1036C30.4083 45.2988 30.0917 45.2988 29.8964 45.1036C29.7012 44.9083 29.7012 44.5917 29.8964 44.3965L31 43.2929ZM29.1036 45.8965C29.2988 46.0917 29.2988 46.4083 29.1036 46.6036L28.2071 47.5H27.125C26.8489 47.5 26.625 47.2762 26.625 47C26.625 46.7239 26.8489 46.5 27.125 46.5H27.7929L28.3964 45.8965C28.5917 45.7012 28.9083 45.7012 29.1036 45.8965ZM20.5 47C20.5 46.7239 20.7239 46.5 21 46.5H21.875C22.1511 46.5 22.375 46.7239 22.375 47C22.375 47.2762 22.1511 47.5 21.875 47.5H21C20.7239 47.5 20.5 47.2762 20.5 47ZM23.125 47C23.125 46.7239 23.3489 46.5 23.625 46.5H25.375C25.6511 46.5 25.875 46.7239 25.875 47C25.875 47.2762 25.6511 47.5 25.375 47.5H23.625C23.3489 47.5 23.125 47.2762 23.125 47ZM33.6464 46.6465C33.8417 46.4512 34.1583 46.4512 34.3536 46.6465L35.3536 47.6465C35.5488 47.8417 35.5488 48.1583 35.3536 48.3536C35.1583 48.5488 34.8417 48.5488 34.6464 48.3536L33.6464 47.3536C33.4512 47.1583 33.4512 46.8417 33.6464 46.6465Z'
          fill='#6D6D70'
        />
        <defs>
          <filter
            id='filter0_d_1_4332'
            x='37'
            y='24'
            width='30'
            height='44'
            filterUnits='userSpaceOnUse'
            color-interpolation-filters='sRGB'
          >
            <feFlood flood-opacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dx='-2' dy='2' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_1_4332'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_1_4332'
              result='shape'
            />
          </filter>
          <linearGradient
            id='paint0_linear_1_4332'
            x1='69'
            y1='71.5'
            x2='88.2622'
            y2='-28.5663'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='#E2E2E2' />
            <stop offset='1' stop-color='#ADAFB5' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_1_4332'
            x1='46.5'
            y1='35'
            x2='67'
            y2='67.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='#FBFBFB' />
            <stop offset='1' stop-color='#9CF9FF' stop-opacity='0' />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

export default WorklistViewerIcon
