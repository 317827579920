import React from 'react';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import EditIcon from './icons/EditIcon';
import ViewIcon from './icons/ViewIcon';
import CopyIcon from './icons/CopyIcon';
import UploadFileIcon from './icons/UploadFileIcon';
import GenerateWorklistFilesIcon from './icons/GenerateWorklistFilesIcon';

function ActionsContainer({
  experimentId,
  handleEdit,
  handleView,
  handleCopy,
  handleUploadFile,
  handleGenerateWorklistFiles,
  highlightBackground,
}) {
  return (
    <div className={`flex items-center gap-4 py-1 px-2 ${highlightBackground ? 'bg-slate-200' : 'bg-white'} `}>
      <button data-tooltip-content="Edit Experiment" data-tooltip-id='my-tooltip' onClick={() => handleEdit(experimentId)}>
        <EditIcon />
      </button>
      <button data-tooltip-content="Instruction Viewer" data-tooltip-id='my-tooltip' onClick={() => handleView(experimentId)}>
        <ViewIcon stroke={'#121314'} />
      </button>
      <button data-tooltip-content="Clone Experiment" data-tooltip-id='my-tooltip' onClick={() => handleCopy(experimentId)}>
        <CopyIcon />
      </button>
      <button data-tooltip-content="Upload Documents" data-tooltip-id='my-tooltip' onClick={() => handleUploadFile(experimentId)}>
        <UploadFileIcon />
      </button>
      <button data-tooltip-content="Generate Worklist Files" data-tooltip-id='my-tooltip' onClick={() => handleGenerateWorklistFiles(experimentId)}>
        <GenerateWorklistFilesIcon />
      </button>
      <ReactTooltip id="my-tooltip" place="top" type="dark" effect="solid"/>
    </div>
  );
}

export default ActionsContainer;
