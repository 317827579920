export function formatFileSize(bytes, decimalPoints = 2) {
  if (bytes < 1024) return bytes + ' Bytes'
  else if (bytes < 1024 ** 2)
    return (bytes / 1024).toFixed(decimalPoints) + ' KB'
  else if (bytes < 1024 ** 3)
    return (bytes / 1024 ** 2).toFixed(decimalPoints) + ' MB'
  else return (bytes / 1024 ** 3).toFixed(decimalPoints) + ' GB'
}

export function localeDate(date, locale = 'en-US') {
  return new Date(date).toLocaleString(locale, date)
}
