import axios from './axios'

export const getMastermixesRequest = () => axios.get('/mastermixes')

export const getMastermixRequest = id => axios.get(`/mastermixes/${id}`)

export const createMastermixRequest = async (experimentId, newMastermix) => {
  return axios.post(`/experiments/${experimentId}/mastermixes`, newMastermix)
}

export const createRecipesRequest = async (mastermixId, newRecipes) => {
  return axios.post(`/mastermixes/${mastermixId}/recipes`, newRecipes)
}

export const updateRecipesRequest = (mastermixId, newRecipes) => {
  return axios.post(`/mastermixes/${mastermixId}/recipes`, newRecipes.recipes)
}

export const updateMastermixRequest = (id, data) =>
  axios.patch(`/experiments/${id}/mastermixes`, data)

export const deleteMastermixRequest = id => axios.delete(`/mastermixes/${id}`)
