import React, { createContext, useContext, useState, useEffect } from 'react'
import { getProfileRequest } from '../api/auth.api'

const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [name, setName] = useState('')
  const [role, setRole] = useState('')

  const logout = () => {
    window.localStorage.removeItem('token')
    setIsAuthenticated(false)
  }

  const fetchUserProfile = async () => {
    setIsLoading(true)
    const token = window.localStorage.getItem('token')
    if (!token) {
      setIsAuthenticated(false)
      setIsLoading(false)
      return
    }

    try {
      const response = await getProfileRequest()
      if (
        response.status === 200 &&
        response.data &&
        response.data.user.confirmed
      ) {
        setName(response.data.user.fullName?.split(' ')[0])
        setRole(response.data.user.role)
        setIsAuthenticated(true)
      } else {
        setIsAuthenticated(false)
      }
    } catch (error) {
      console.error('Authentication verification failed', error)
      setIsAuthenticated(false)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchUserProfile()
  }, [])

  const value = {
    isAuthenticated,
    setIsAuthenticated,
    isLoading,
    logout,
    name,
    role,
    fetchUserProfile,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
