import React from 'react'

function TXTFileIcon() {
  return (
    <div className='w-20 h-20'>
      <svg
        width='80'
        height='80'
        viewBox='0 0 80 80'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17 73H60C61.1046 73 62 72.1046 62 71V9C62 7.89543 61.1046 7 60 7H31.1846C30.6428 7 30.1241 7.21984 29.7473 7.60923L15.5627 22.2681C15.2018 22.6411 15 23.1398 15 23.6589V71C15 72.1046 15.8954 73 17 73Z'
          fill='url(#paint0_linear_1_4325)'
        />
        <path
          d='M30 24H15V22.8284C15 22.298 15.2107 21.7893 15.5858 21.4142L29.4142 7.58579C29.7893 7.21071 30.298 7 30.8284 7H32V22C32 23.1046 31.1046 24 30 24Z'
          fill='#F2F2F3'
        />
        <path
          d='M55.9586 38H68.3334V41.2924H64.1815V51.3333H60.1105V41.2924H55.9586V38Z'
          fill='black'
        />
        <path
          d='M41.6517 38H46.1361L48.4726 42.1019L50.7373 38H55.1767L51.0788 44.4575L55.5632 51.3333H50.9889L48.3917 47.0496L45.7856 51.3333H41.2383L45.7856 44.3847L41.6517 38Z'
          fill='black'
        />
        <path
          d='M28.3333 38H40.7081V41.2924H36.5562V51.3333H32.4852V41.2924H28.3333V38Z'
          fill='black'
        />
        <defs>
          <linearGradient
            id='paint0_linear_1_4325'
            x1='69'
            y1='71.5'
            x2='88.2622'
            y2='-28.5663'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='#E2E2E2' />
            <stop offset='1' stop-color='#ADAFB5' />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

export default TXTFileIcon
