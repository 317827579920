import axios from './axios'

export const loginRequest = data => axios.post('/auth/login', data)

export const registerRequest = userData => {
  return axios.post('/auth/register', userData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const forgotPasswordRequest = email => {
  return axios.post(
    '/auth/forgot-password',
    { email },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

export const resetPasswordRequest = ({ password, token }) => {
  return axios.post(
    '/auth/reset-password',
    { password, token },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

export const confirmUserRequest = token => {
  return axios.get(`/auth/confirm/${token}`)
}

export const getProfileRequest = () => {
  return axios.get('/auth/profile')
}

export const resendConfirmEmailRequest = email => {
  return axios.post(
    '/auth/resend-confirm-email',
    { email },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}
