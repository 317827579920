import axios from './axios'

export const getExperimentsRequest = () => axios.get('/experiments')

export const getExperimentRequest = (id) => axios.get(`/experiments/${id}`)

export const createExperimentRequest = (data) => axios.post('/experiments', data)

export const updateExperimentRequest = (id, data) => axios.patch(`/experiments/${id}`, data)

export const deleteExperimentRequest = (id) => axios.delete(`/experiments/${id}`)