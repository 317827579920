import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { confirmUserRequest } from '../../api/auth.api'
import { useAuth } from '../../context/AuthContext'
import { DNA } from 'react-loader-spinner'

function ConfirmUserPage() {
  const { confirmToken } = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const { setIsAuthenticated, fetchUserProfile } = useAuth()
  const [errorMessage, setErrorMessage] = useState('')
  const [confirmErrorMessage, setConfirmErrorMessage] = useState('')

  useEffect(() => {
    const confirmUser = async () => {
      try {
        const response = await confirmUserRequest(confirmToken)
        if (response.data.message === 'Your account has been confirmed.') {
          window.localStorage.setItem('token', response.data.token)
          await fetchUserProfile()
          navigate('/experiments')
        }
      } catch (error) {
        if (error.response.data.message === 'Account already confirmed.') {
          setErrorMessage('Account already confirmed. Please log in.')
          setTimeout(() => {
            navigate('/login')
          }, 3000)
        }
        if (
          error.response.data.message ===
          'The confirmation link is invalid or has expired.'
        ) {
          setTimeout(() => {
            navigate('/resend-confirmation-email')
          }, 5000)
          setConfirmErrorMessage(
            'The link is invalid or has expired. Enter your email to resend the confirmation. Redirecting in 5 seconds.'
          )
        }
        console.error(
          error.response ? error.response.data.message : error.message
        )
        setIsLoading(false)
      }
    }
    confirmUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmToken, navigate, setIsAuthenticated])

  if (isLoading)
    return (
      <div className='flex items-center justify-center h-screen'>
        <div className='flex flex-col items-center justify-center bg-white p-2.5 rounded-lg grayscale'>
          <DNA height={100} width={100} />
          <div>Loading...</div>
        </div>
      </div>
    )

  return (
    <div className='flex justify-center flex-col items-center h-screen w-screen m-0'>
      <div className='w-[438px] h-[214px] p-7 rounded-xl flex-col justify-start items-center gap-7 inline-flex'>
        <div className='self-stretch h-[76px] flex-col justify-start items-center gap-4 flex'>
          <div className="text-center text-neutral-600 text-2xl font-bold font-['Nunito Sans'] leading-tight">
            {errorMessage}
            {
              confirmErrorMessage
              // resend confirmation email card below
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmUserPage
